import { useEffect, useState } from 'react';

import { FullNameLabel } from '../../components/labels/FullNameLabel';
import { useGetClientsForSelectorLazyQuery } from '../../graphql/generated';

import SelectField from './SelectField';

export const ClientSelectorField = ({
  name,
  placeholder,
  selectPlaceholder,
  className,
  idToExclude,
}: {
  name: string;
  placeholder?: string;
  selectPlaceholder?: string;
  className?: string;
  idToExclude?: number | null;
  onValueChanged?: (selectedId: string | null) => Promise<void>;
}) => {
  const [getClients] = useGetClientsForSelectorLazyQuery();

  const [uiClients, setUiClients] = useState<{ firstName: string; lastName: string; id: number }[]>(
    []
  );

  useEffect(() => {
    async function load() {
      const { data } = await getClients();

      setUiClients(data!.clients!.items!.filter((x) => x.id != idToExclude));
    }

    load();
  }, []);

  const options = uiClients.map((d) => {
    return (
      <option value={d.id} key={d.id.toString()}>
        {FullNameLabel({
          firstName: d.firstName,
          lastName: d.lastName,
        })}
      </option>
    );
  });

  return (
    <SelectField name={name} label={placeholder}>
      {options}
    </SelectField>
  );
};
