import { Avatar, Sidebar } from 'flowbite-react';
import { useMemo } from 'react';
import { FaBook } from 'react-icons/fa';
import {
  HiHome,
  HiUsers,
  HiAdjustments,
  HiClipboard,
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from 'react-icons/hi';
import { NavLink, useLocation } from 'react-router-dom';

import { useGlobalActions, useGlobalState } from '../../context/AppContext';
import useFinologyTranslation from '../../translations/useFinologyTranslation';
import { isClient } from '../../utils';

export const SideNavigation = () => {
  const { user, collapseSideNavigation } = useGlobalState();
  const { triggerCollapseSideNavigation } = useGlobalActions();
  const { t } = useFinologyTranslation();
  const location = useLocation();

  const navLinks = useMemo(() => {
    if (user?.roles?.includes('Advisor')) {
      return [
        { to: '/dashboard', label: 'Dashboard', icon: HiHome },
        { to: '/clients', label: t('borrowers.uppercase'), icon: HiUsers },
        { to: '/calculator', label: 'Liability Planner', icon: HiAdjustments },
        { to: '/resources', label: 'Finology Software', icon: FaBook },
      ];
    } else {
      return [
        { to: '/dashboard', label: 'Dashboard', icon: HiHome },
        { to: '/advisor-applications', label: 'Advisor Applications', icon: HiClipboard },
      ];
    }
  }, [user]);

  return (
    <div
      className={` ${
        collapseSideNavigation ? `w-[68px] min-w-[68px]` : `w-[220px] min-w-[220px]`
      } `}
    >
      <Sidebar
        className={`${collapseSideNavigation ? `w-[68px]` : `w-[220px]`} fixed top-0 z-10`}
        collapsed={collapseSideNavigation}
        aria-label="Sidebar"
      >
        <NavLink to="/">
          <Sidebar.Logo href="#" img="/images/logo.svg" imgAlt="Finology Logo">
          </Sidebar.Logo>
        </NavLink>
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            {navLinks.map((nl) => {
              return (
                <Sidebar.Item
                  as={NavLink}
                  key={nl.to}
                  to={nl.to}
                  icon={nl.icon}
                  active={location.pathname.startsWith(nl.to)}
                >
                  {nl.label}
                </Sidebar.Item>
              );
            })}
            {user?.isSuperAdmin ? (
              <>
                <Sidebar.Item
                  as={NavLink}
                  to={'/super-admin/dashboard'}
                  icon={HiAdjustments}
                  active={location.pathname.startsWith('/super-admin/dashboard')}
                >
                  Admin
                </Sidebar.Item>
                <Sidebar.Item
                  as={NavLink}
                  to={'/super-admin/advisors'}
                  icon={HiAdjustments}
                  active={location.pathname.startsWith('/super-admin/advisors')}
                >
                  Admin - Advisors
                </Sidebar.Item>
                <Sidebar.Item
                  as={NavLink}
                  to={'/super-admin/clients'}
                  icon={HiAdjustments}
                  active={location.pathname.startsWith('/super-admin/clients')}
                >
                  Admin - Clients
                </Sidebar.Item>
              </>
            ) : null}
          </Sidebar.ItemGroup>
        </Sidebar.Items>

        {/* {!collapseSideNavigation && (
          <>
            <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
            <div className="mx-4 mt-3">
              <NavLink to="demo-video" className="text-white underline">
                Demo Video
              </NavLink>
            </div>
          </>
        )} */}

        <div
          style={{
            width: collapseSideNavigation ? undefined : 178,
          }}
          className="text-neutral-100 self-stretch grow shrink basis-0 flex-col justify-end gap-4 flex"
        >
          <div className="flex-col justify-end gap-4 inline-flex">
            <NavLink to={isClient(user) ? '/settings/profile' : '/settings'}>
              <div className="py-4 border-b border-blue-50 border-opacity-20 flex-col justify-start items-start gap-4 flex cursor-pointer">
                <div className="justify-start items-center inline-flex">
                  <div className="grow shrink basis-0 h-9 justify-start items-center gap-2 flex">
                    <Avatar rounded img={user?.profilePicture} />
                    {collapseSideNavigation ? null : (
                      <div className="flex-col justify-start items-start gap-0.5 inline-flex ">
                        <div className="text-base font-semibold truncate max-w-[140px]">
                          {user!.firstName} {user?.lastName || ''}
                        </div>
                        <div className="text-gray-400 text-sm font-normal lowercase truncate max-w-[140px]">
                          {user!.email}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </NavLink>
            <div className="py-2.5 flex-col justify-start items-start gap-4 flex">
              <div className="px-2 justify-start items-center inline-flex">
                <div className="grow shrink basis-0 h-3.5 justify-start items-center gap-3 flex">
                  <div
                    onClick={() => triggerCollapseSideNavigation(!collapseSideNavigation)}
                    className="w-3.5 h-3.5 relative cursor-pointer"
                  >
                    {collapseSideNavigation ? (
                      <HiOutlineChevronDoubleRight />
                    ) : (
                      <HiOutlineChevronDoubleLeft />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};
