import {
  Card,
  Badge,
  Dropdown,
  Spinner,
  ToggleSwitch,
  Tooltip,
  Tabs,
  Button,
} from 'flowbite-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  HiOutlinePlusCircle,
  HiOutlineExclamationCircle,
  HiUserCircle,
  HiOutlinePencilAlt,
  HiPlus,
} from 'react-icons/hi';
import { PiUserFill, PiUploadFill } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DegreeHatIcon } from '../../../assets/degree-hat.svg';
import { ReactComponent as FileChartBarIcon } from '../../../assets/file-chart-bar.svg';
import { CardWithItems } from '../../../components/cards/Cards';
import { PieChart } from '../../../components/charts';
import { FullNameLabel } from '../../../components/labels/FullNameLabel';
import { TaxFilingTypeLabel } from '../../../components/labels/TaxFilingTypeLabel';
import { FinoLink } from '../../../components/links/FinoLink';
import { FlyoutLink } from '../../../components/links/FlyoutLink';
import { useGlobalState } from '../../../context/AppContext';
import { GetClientDashboardQuery, useGetClientDashboardQuery } from '../../../graphql/generated';
import { useFlyoutNavigate } from '../../../hooks/useFlyoutNavigate';
import useFinologyTranslation from '../../../translations/useFinologyTranslation';
import { toDollars } from '../../../util/currency.formatter';
import { toDateShortString } from '../../../util/date.formatter';
import { toPercentage } from '../../../util/number.formatter';
import { getInitials, isClient } from '../../../utils';

import { FederalLoans } from './loans/FederalLoans';
import { PrivateLoans } from './loans/PrivateLoans';

const RepaymentPlanBadge = ({ plan }: { plan: string }) => {
  let color: string | undefined;

  switch (plan?.toLocaleLowerCase()) {
    case 'revised pay as you earn':
      color = 'red';
      plan = 'repaye';
      break;

    case 'save':
      color = 'indigo';
      break;

    case 'pay as you earn':
      color = 'orange';
      plan = 'PAYE';
      break;

    case 'income based':
      color = 'purple';
      plan = 'IBR';
      break;

    case 'income contingent':
      color = 'pink';
      plan = 'ICR';
      break;

    case 'standard':
    case 'extended':
    case 'consolidated':
      color = 'teal';
      break;

    case 'graduated':
      color = undefined;
      break;
  }

  return (
    <Badge color={color} className="inline mr-1">
      {plan?.toUpperCase()}
    </Badge>
  );
};

const MOCK_DATA_PVI = [
  {
    type: 'Principal',
    amount: 214125,
  },
  {
    type: 'Interest',
    amount: 25333,
  },
];

const MOCK_DATA_DEBT_BY_TYPE = [
  {
    __typename: 'LoanWithType',
    type: 'Direct Stafford Unubsidized',
    amount: 154080,
  },
  {
    __typename: 'LoanWithType',
    type: 'Direct Plus Graduate',
    amount: 54053,
  },
  {
    __typename: 'LoanWithType',
    type: 'Other Loan Types',
    amount: 15788,
  },
];

const HeaderItem = ({
  title,
  tooltip,
  text,
}: {
  title: ReactNode;
  text?: ReactNode;
  tooltip?: ReactNode;
}) => {
  return (
    <div className="flex grow flex-col gap-4 max-w-[20%]">
      <span className="text-sm font-medium text-gray-500">
        {tooltip ? (
          <Tooltip content={tooltip}>
            <div className="flex items-center">
              {title}&nbsp;
              {<HiOutlineExclamationCircle className="inline" />}
            </div>
          </Tooltip>
        ) : (
          title
        )}
      </span>
      <div className={'flex gap-x-2 items-center text-2xl font-medium'}>
        <span className={text === null ? 'text-gray-500' : 'text-primary-800'}>
          {text === undefined ? '' : text == null ? 'n/a' : text}
        </span>
      </div>
    </div>
  );
};

const ClientDashboard = ({
  clientId,
  data,
}: {
  clientId: number;
  data: GetClientDashboardQuery | undefined;
}) => {
  const { t } = useFinologyTranslation();
  const navigate = useNavigate();
  const [showPrivateChart, setShowPrivateChart] = useState(false);

  useEffect(() => {
    if (data) {
      const onlyPrivateData =
        !data.clientDashboardDetails?.loansPerType &&
        !!data.clientDashboardDetails?.privateLoansPerType;

      setShowPrivateChart(onlyPrivateData);
    }
  }, [data]);

  const handleCardSwitch = () => {
    setShowPrivateChart(!showPrivateChart);
  };

  const { user } = useGlobalState();

  const principalVsInterestChartData = useMemo(() => {
    return data?.clientDashboardDetails && data?.clientDashboardDetails.numberOfFederalLoans > 0
      ? [
          {
            type: 'Principal',
            amount: data.clientDashboardDetails!.principalLoansSum,
          },
          {
            type: 'Interest',
            amount: data.clientDashboardDetails!.interestLoansSum,
          },
        ]
      : undefined;
  }, [data?.clientDashboardDetails]);

  const shouldLockScreen = isClient(user) && !data?.clientDashboardDetails?.hasLoans;

  const hasPrivateAndFederalDebt =
    data?.clientDashboardDetails?.privateLoansPerType && data?.clientDashboardDetails?.loansPerType;

  return (
    <div>
      {/* Top */}
      <div className="flex flex-col gap-8 py-8 px-10 mx-[-24px]  bg-gray-50">
        <div className="flex gap-y-4">
          <HeaderItem
            title="No. of Federal Loans"
            text={data?.clientDashboardDetails?.numberOfFederalLoans.toString()}
          />
          <HeaderItem
            title="No. of Private Loans"
            text={data?.clientDashboardDetails?.numberOfPrivateLoans.toString()}
          />
          <HeaderItem
            title="Household Size"
            tooltip={t('pages.client_dashboard.tooltips.household_size')}
            text={data?.clientDashboardDetails?.householdSize}
          />
          <HeaderItem
            title="Tax Filing Type"
            text={
              data?.client?.taxFilingType ? (
                <TaxFilingTypeLabel taxFilingType={data!.client!.taxFilingType} />
              ) : null
            }
          />
          <HeaderItem
            title={
              <div className="flex gap-1">
                <span>Partner/Spouse</span>
                {!data?.client?.spouse && !isClient(user) ? (
                  <Dropdown
                    inline
                    arrowIcon={false}
                    label={<HiOutlinePlusCircle className="hover:text-primary" />}
                  >
                    <Dropdown.Item
                      onClick={() => navigate(`?flyout=pick-spouse&clientId=${clientId}`)}
                    >
                      Link Existing {t('borrower.uppercase')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => navigate(`?flyout=create-client-simple&spouseId=${clientId}`)}
                    >
                      Add New {t('borrower.uppercase')} - NSLDS
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => navigate(`?flyout=upsert-client&spouseId=${clientId}`)}
                    >
                      Add New {t('borrower.uppercase')} - Manual
                    </Dropdown.Item>
                  </Dropdown>
                ) : null}
              </div>
            }
            text={
              <>
                {data?.client?.spouse ? (
                  isClient(user) ? (
                    <span>
                      {FullNameLabel({
                        firstName: data.client.spouse.firstName,
                        lastName: data.client.spouse.lastName,
                      })}
                    </span>
                  ) : (
                    <FinoLink
                      className="hover:text-indigo-600"
                      to={`/clients/${data?.client?.spouse?.id}`}
                      label={FullNameLabel({
                        firstName: data.client.spouse.firstName,
                        lastName: data.client.spouse.lastName,
                      })}
                    />
                  )
                ) : (
                  <div className="flex gap-x-4 items-start">
                    <span>n/a</span>
                  </div>
                )}
              </>
            }
          />
        </div>

        <div className="flex justify-start gap-y-4">
          <HeaderItem
            title="Federal Loan Repayment Plan Begin Date"
            text={toDateShortString(
              data!.clientDashboardDetails!.earliestFederalLoanRepaymentPlanBeginDate
            )}
          />
          <HeaderItem
            title="Private Loan Start Date"
            text={toDateShortString(data!.clientDashboardDetails!.earliestPrivateLoanStartDate)}
          />
          <HeaderItem
            title="PSLF (Certified Months)"
            text={
              data?.clientDashboardDetails?.pslfCumulativeMatchedMonths?.length
                ? data?.clientDashboardDetails?.pslfCumulativeMatchedMonths.slice(0, 3).join(', ')
                : null
            }
            tooltip={
              data?.clientDashboardDetails?.pslfCumulativeMatchedMonths &&
              data?.clientDashboardDetails?.pslfCumulativeMatchedMonths.length > 1 ? (
                <div className="flex flex-col gap-4">
                  <div>
                    <div>{t('pages.client_dashboard.tooltips.multiple_pslf')}</div>
                    <div>Resubmit PSLF Certification Application.</div>
                    <div>Contact Servicer.</div>
                  </div>
                  <div>
                    Months: {data.clientDashboardDetails.pslfCumulativeMatchedMonths.join(', ')}{' '}
                  </div>
                </div>
              ) : null
            }
          />
          <HeaderItem
            title="Federal Tax Rate"
            text={toPercentage(data?.clientDashboardDetails?.federalTaxRate)}
          />
          <HeaderItem title="State Tax Rate" text={toPercentage(data?.client?.stateTaxRate)} />
        </div>
        <div className="flex justify-start gap-y-4">
          <HeaderItem
            title={
              data?.clientDashboardDetails?.repaymentPlanTypes &&
              data?.clientDashboardDetails?.repaymentPlanTypes.length > 1
                ? `Repayment Plans`
                : 'Repayment Plan'
            }
            text={
              data?.clientDashboardDetails?.repaymentPlanTypes?.length
                ? data?.clientDashboardDetails?.repaymentPlanTypes
                    .slice(0, 3)
                    .map((rp) => <RepaymentPlanBadge key={rp} plan={rp} />)
                : null
            }
            tooltip={
              data?.clientDashboardDetails?.repaymentPlanTypes &&
              data?.clientDashboardDetails?.repaymentPlanTypes.length > 1 ? (
                <div className="flex flex-col gap-4">
                  <div>{t('pages.client_dashboard.tooltips.multiple_repayment_types')}</div>
                  <div>
                    Repayment plan types:{' '}
                    {data.clientDashboardDetails.repaymentPlanTypes.join(', ')}{' '}
                  </div>
                </div>
              ) : null
            }
          />

          <HeaderItem
            title={
              data?.clientDashboardDetails?.spouseRepaymentPlanTypes &&
              data?.clientDashboardDetails?.spouseRepaymentPlanTypes.length > 1
                ? 'Spouse Repayment Plans'
                : 'Spouse Repayment Plan'
            }
            text={
              data?.clientDashboardDetails?.spouseRepaymentPlanTypes?.length
                ? data?.clientDashboardDetails?.spouseRepaymentPlanTypes
                    .slice(0, 3)
                    .map((rp) => <RepaymentPlanBadge key={rp} plan={rp} />)
                : null
            }
            tooltip={
              data?.clientDashboardDetails?.spouseRepaymentPlanTypes &&
              data?.clientDashboardDetails?.spouseRepaymentPlanTypes.length > 1 ? (
                <div className="flex flex-col gap-4">
                  <div>
                    Spouse has <span className="font-semibold">multiple repayment plan types</span>
                  </div>
                  <div>
                    Repayment plan types:{' '}
                    {data.clientDashboardDetails.spouseRepaymentPlanTypes.join(', ')}{' '}
                  </div>
                </div>
              ) : null
            }
          />
        </div>
      </div>
      <div className="relative">
        {shouldLockScreen && <LockedScreenMessage />}

        <div className="mt-4">
          <div className="grid grid-cols-4 gap-10 max-[1300px]:grid-cols-2">
            <CardWithItems
              upperRowItems={[
                {
                  text: 'Total Loan Balance',
                  value: toDollars(
                    (data?.clientDashboardDetails?.creditCardLoansSum || 0) +
                      (data?.clientDashboardDetails?.privateLoansSum || 0) +
                      (data?.clientDashboardDetails?.federalTotalLoanAmount || 0)
                  ),
                },
              ]}
              lowerRowItems={[
                {
                  text: 'Private Loan Total Balance',
                  value: toDollars(data?.clientDashboardDetails?.privateLoansSum),
                },
                {
                  text: 'Credit Card Loan Total Balance',
                  value: toDollars(data?.clientDashboardDetails?.creditCardLoansSum),
                },
              ]}
            />
            <CardWithItems
              upperRowItems={[
                {
                  text: 'Federal Loan Total Balance',
                  value: toDollars(data?.clientDashboardDetails?.federalTotalLoanAmount),
                },
              ]}
              lowerRowItems={[
                {
                  text: 'Principal Balance',
                  value: toDollars(data?.clientDashboardDetails?.principalLoansSum),
                },
                {
                  text: 'Interest Balance',
                  value: toDollars(data?.clientDashboardDetails?.interestLoansSum),
                },
              ]}
            />
            <CardWithItems
              upperRowItems={[
                {
                  text: 'Weighted Avg. Rate',
                  value: toPercentage(data?.clientDashboardDetails?.averageInterestRate),
                },
              ]}
              lowerRowItems={[
                {
                  text: 'Federal',
                  value: toPercentage(data?.clientDashboardDetails?.averageFederalInterestRate),
                },
                {
                  text: 'Private',
                  value: toPercentage(data?.clientDashboardDetails?.averagePrivateInterestRate),
                },
              ]}
            />

            <CardWithItems
              upperRowItems={[
                {
                  text: (
                    <Tooltip content={'Total Monthly Payment = Federal + Private'}>
                      <div className="flex gap-1 items-center text-gray-500 text-sm font-medium">
                        Total Monthly Payment <HiOutlineExclamationCircle className="inline" />
                      </div>
                    </Tooltip>
                  ),
                  value: toDollars(
                    data?.clientDashboardDetails?.federalLoansMonthlyPayment +
                      data?.clientDashboardDetails?.privateLoansMonthlyPayment
                  ),
                },
              ]}
              lowerRowItems={[
                {
                  text: (
                    <Tooltip content={'Sum of Repayment Plan Scheduled Amounts'}>
                      <div className="flex gap-1 items-center text-gray-500 text-sm font-medium">
                        Federal <HiOutlineExclamationCircle className="inline" />
                      </div>
                    </Tooltip>
                  ),
                  value: toDollars(data?.clientDashboardDetails?.federalLoansMonthlyPayment),
                },
                {
                  text: 'Private',
                  value: toDollars(data?.clientDashboardDetails?.privateLoansMonthlyPayment),
                },
              ]}
            />
          </div>
        </div>

        <div className="mt-10 grid gap-10 grid-cols-2">
          <CardWithClient
            data={
              showPrivateChart
                ? data?.clientDashboardDetails?.privateLoansPerType
                : data?.clientDashboardDetails?.loansPerType
            }
            handleChange={hasPrivateAndFederalDebt && handleCardSwitch}
            emptyStateLabel={
              showPrivateChart
                ? 'Add Private Loan(s) to see data.'
                : 'Add Federal Loan(s) to see data.'
            }
            switchLabels={{ pre: 'Federal', post: 'Private' }}
            title={'Balance By Type'}
            isClient={isClient(user)}
            hasLoans={
              showPrivateChart
                ? data?.clientDashboardDetails?.privateLoansPerType
                : data?.clientDashboardDetails?.hasLoans
            }
            switchChecked={showPrivateChart}
            mockData={MOCK_DATA_DEBT_BY_TYPE}
          />
          <CardWithClient
            data={principalVsInterestChartData}
            title="Principal vs Interest - Federal Student Loans"
            isClient={isClient(user)}
            hasLoans={data?.clientDashboardDetails?.hasLoans}
            mockData={MOCK_DATA_PVI}
            emptyStateLabel={'Add Federal Loan(s) to see data.'}
          />
        </div>
      </div>
    </div>
  );
};

const LockedScreenMessage = () => (
  <div
    className="absolute top-0 h-full -left-4 flex z-10 justify-center items-center "
    style={{
      width: '102%',
      backgroundColor: 'rgba(77, 77, 77, 0.75)',
    }}
  >
    <div className="text-white text-2xl max-w-xl font-medium">
      Please input your loan information or ask your advisor to add loan details, to enhance your
      Finology account.
    </div>
  </div>
);

const CardWithClient = ({
  data,
  isClient,
  hasLoans,
  title,
  mockData,
  handleChange,
  switchChecked,
  switchLabels,
  emptyStateLabel,
}: any) => {
  if (hasLoans && data)
    return (
      <Card>
        <div className="flex flex-col">
          <div className="flex justify-between">
            {title}
            {handleChange ? (
              <div className="flex gap-x-2 items-center">
                {switchLabels?.pre && <label className="cursor-pointer">{switchLabels?.pre}</label>}
                <ToggleSwitch
                  color="primary"
                  label=""
                  checked={switchChecked}
                  onChange={handleChange}
                />
                {switchLabels?.post && (
                  <label className="cursor-pointer">{switchLabels?.post}</label>
                )}
              </div>
            ) : null}
          </div>

          <PieChart data={data} />
        </div>
      </Card>
    );

  if (isClient && !hasLoans)
    return (
      <Card title={title}>
        <PieChart data={mockData} />
      </Card>
    );

  return (
    <Card title={title}>
      <div className="flex flex-col">
        <div className="flex justify-between">
          {title}
          {handleChange ? (
            <div className="flex gap-x-2 items-center">
              {switchLabels?.pre && <label className="cursor-pointer">{switchLabels?.pre}</label>}
              <ToggleSwitch
                color="primary"
                label=""
                checked={switchChecked}
                onChange={handleChange}
              />
              {switchLabels?.post && <label className="cursor-pointer">{switchLabels?.post}</label>}
            </div>
          ) : null}
        </div>
      </div>
      <div className="h-80 flex items-center justify-center whitespace-nowrap">
        {emptyStateLabel || 'No data to be displayed.'}
      </div>
    </Card>
  );
};

export const ClientDetails = ({ clientId }: { clientId: number }) => {
  const { loading, data } = useGetClientDashboardQuery({
    variables: { clientId: clientId },
    fetchPolicy: 'network-only',
  });
  const { navigate: flyoutNavigate } = useFlyoutNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const { t } = useFinologyTranslation();

  const tabs = [
    {
      title: 'Client Details',
      Icon: (
        <HiUserCircle
          className={activeTab === 0 ? `fill-primary-600` : 'fill-gray-500'}
          size={20}
        />
      ),
      content: <ClientDashboard clientId={clientId} data={data} />,
      active: activeTab === 0,
    },
    {
      title: 'Federal Loans',
      Icon: (
        <DegreeHatIcon
          className={`h-5 ${
            activeTab === 1
              ? `fill-primary-600 stroke-primary-600`
              : 'fill-gray-500 stroke-gray-500'
          } `}
        />
      ),
      content: <FederalLoans clientId={clientId} />,
      active: activeTab === 1,
    },
    {
      title: 'Private Loans',
      Icon: <PiUserFill className={activeTab === 2 ? `fill-primary-600` : 'fill-gray-500'} />,
      content: <PrivateLoans clientId={clientId} />,
      active: activeTab === 2,
    },
    {
      title: 'Reports (coming soon)',
      Icon: (
        <FileChartBarIcon
          className={`${activeTab === 3 ? `fill-primary-600` : 'fill-gray-500'} h-4 opacity-40`}
        />
      ),
      content: null,
      active: activeTab === 3,
      disabled: true,
    },
  ];

  if (loading) return <Spinner />;

  return (
    <>
      <div className="flex justify-between items-center gap-4 p-6 border border-gray-200 bg-gray-50 rounded-lg">
        <div className="flex items-center gap-4">
          <div className="w-12 h-12 bg-teal-200 rounded-full flex items-center justify-center text-lg text-gray-900">
            {getInitials(data!.client!.firstName, data!.client!.lastName)}
          </div>
          <div className="flex flex-col gap-1 justify-start">
            <span className="text-black text-lg font-semibold">{data!.client!.fullName}</span>
            <div className="flex justify-start items-center gap-1">
              <HiOutlinePencilAlt />
              <FlyoutLink
                className=" text-gray-800 hover:text-gray-900"
                flyoutId="upsert-client"
                params={`clientId=${clientId}`}
              >
                {t('pages.client_dashboard.labels.edit_profile')}
              </FlyoutLink>
            </div>
          </div>
        </div>
        <div className="flex gap-3">
          <Button color="primary" onClick={() => navigate(`${location.pathname}/calculator`)}>
            Liability Planner
          </Button>
          <Button
            color="secondaryLight"
            onClick={() => flyoutNavigate('client-federal-loan-versions', `clientId=${clientId}`)}
          >
            {' '}
            Versions
          </Button>

          <div className="border-l border-gray-300 min-h-full mx-1"></div>
          <Dropdown
            color="primary"
            arrowIcon={false}
            label={
              <>
                <HiPlus /> &nbsp; Add Loan
              </>
            }
          >
            <Dropdown.Item
              onClick={() => {
                setActiveTab(1);
                flyoutNavigate('upsert-federal-loan', `clientId=${clientId}`);
              }}
            >
              Federal
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setActiveTab(2);
                flyoutNavigate('upsert-private-loan', `clientId=${clientId}`);
              }}
            >
              Private
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setActiveTab(2);
                flyoutNavigate('upsert-credit-card', `clientId=${clientId}`);
              }}
            >
              Credit Card
            </Dropdown.Item>
          </Dropdown>
          <Button
            color="secondaryLight"
            onClick={() => navigate(`?flyout=upload-nslds&clientId=${clientId}`)}
          >
            <PiUploadFill size={16} className="fill-blue-700 mr-1" />
            Upload NSLDS
          </Button>
        </div>
      </div>
      <Tabs
        onActiveTabChange={(index) => setActiveTab(index)}
        theme={{
          base: 'flex flex-col',
          tablist: {
            base: 'mx-[-24px] z-1 px-10 flex text-center flex-wrap border-b border-zinc-300 mt-4 drop-shadow-lg',
          },
          tabpanel: 'p-0',
        }}
        className="mt-4 "
        style="underline"
      >
        {tabs.map((tab, index) => (
          <Tabs.Item
            key={index}
            active={tab.active}
            disabled={tab.disabled}
            title={
              <div className="flex items-center justify-center gap-1">
                {tab.Icon}
                {tab.title}
              </div>
            }
          >
            {tab.content}
          </Tabs.Item>
        ))}
      </Tabs>
    </>
  );
};
