import dayjs from 'dayjs';
import { Alert, Button } from 'flowbite-react';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { HiInformationCircle } from 'react-icons/hi';

import { toDollars } from '../../../util/currency.formatter';
import { toDateShortString } from '../../../util/date.formatter';
import { toPercentage } from '../../../util/number.formatter';
import { CheckboxField } from '../../formComponents/CheckboxField';
import { DateField } from '../../formComponents/DateField';
import { TextInputField } from '../../formComponents/InputField';
import SelectField from '../../formComponents/SelectField';
import {
  CalculatorFederalLoanFormValue,
  CalculatorFormValues,
} from '../form/EditCalculatorDetails';

interface Props {
  clientName: string;
  initialData: any;
}

interface FederalLoanProps {
  loan: CalculatorFederalLoanFormValue;
  fieldPrefix: string;
  prefix: 'loans';
}

const knownRepaymentPlanTypesAcronyms = {
  EXTENDED: 'EXTENDED',
  'INCOME CONTINGENT': 'ICR',
  GRADUATED: 'GRADUATED',
  STANDARD: 'STANDARD',
  SAVE: 'SAVE',
  'PAY AS YOU EARN': 'PAYE',
  'REVISED PAY AS YOU EARN': 'REPAYE',
  'INCOME BASED': 'IBR',
  CONSOLIDATED: 'CONSOLIDATED',
} as { [key: string]: string };

const renderRepaymentPlanTypeAcronym = (type: string) => {
  let result = type;

  Object.keys(knownRepaymentPlanTypesAcronyms).forEach((key) => {
    if (type.includes(key)) {
      result = knownRepaymentPlanTypesAcronyms[key];
    }
  });
  return result;
};

const FederalLoanItem = ({ loan, fieldPrefix, prefix }: FederalLoanProps) => {
  const form = useFormikContext<{
    loans: CalculatorFederalLoanFormValue[];
    isPslfActive: boolean;
    isTimeBasedForgivnessActive: boolean;
  }>();

  let date = loan.loanDate as any;
  if (typeof date === 'object') {
    date = loan.loanDate?.toDate();
  } else {
    date = dayjs(loan.loanDate)?.toDate();
  }

  const isPslfActive = form.values.isPslfActive;
  const isTimeBasedForgivnessActive = form.values.isTimeBasedForgivnessActive;

  return (
    <div className="grid grid-cols-8 gap-y-6 gap-x-3 items-center border border-gray-200 border-b-1 border-t-0 border-x-0 pb-3 max-[1025px]:flex max-[1025px]:flex-wrap px-6 max-[1025px]:justify-start w-full">
      <div className="h-11 pt-2 pb-4 justify-between items-center inline-flex col-span-8">
        <div className="h-5 justify-start items-center gap-2 flex">
          {loan.repaymentPlanType ? (
            <div className="px-2.5 py-0.5 bg-green-100 rounded-md justify-center items-center gap-1 flex">
              <div className="text-center text-emerald-900 text-xs font-medium">
                {renderRepaymentPlanTypeAcronym(loan.repaymentPlanType)}
              </div>
            </div>
          ) : null}
          <div className="text-gray-900 text-base font-semibold">{loan.type}</div>
        </div>
        <div>
          <CheckboxField
            name={`${fieldPrefix}.graduate`}
            label="Graduate Loan"
            disabled={loan.type?.toLowerCase()?.includes('graduate')}
          />
        </div>
      </div>
      <div className="grow shrink basis-0 self-stretch pr-6 rounded-lg flex-col justify-start items-start inline-flex col-span-1 mt-1 max-[1500px]:col-span-3">
        <div className="text-gray-500 text-xs font-medium">Current Balance</div>
        <div className="self-stretch grow shrink basis-0 justify-start items-center gap-1.5 inline-flex">
          <div className="text-gray-900 text-sm font-medium">
            {toDollars(loan.principalBalance + loan.interestBalance)}
          </div>
        </div>
      </div>
      <div className="w-36 self-stretch pr-6 rounded-lg flex-col justify-start items-start inline-flex col-span-1 mt-1 max-[1500px]:col-span-3">
        <div className="text-gray-500 text-xs font-medium">Interest Rate</div>
        <div className="self-stretch grow shrink basis-0 justify-start items-center gap-1.5 inline-flex">
          <div className="text-gray-900 text-sm font-medium">{toPercentage(loan.interestRate)}</div>
        </div>
      </div>
      <div className="w-44 self-stretch pr-6 rounded-lg flex-col justify-start items-start  inline-flex col-span-1 mt-1 max-[1500px]:col-span-2">
        <div className="text-gray-500 text-xs font-medium">Loan Origination Date</div>
        <div className="self-stretch grow shrink basis-0 justify-start items-center gap-1.5 inline-flex">
          <div className="text-gray-900 text-sm font-medium">{toDateShortString(date)}</div>
        </div>
      </div>
      <DateField
        name={`${fieldPrefix}.repaymentBeginDate`}
        placeholder="Repayment Begin Date"
        className="col-span-2 max-[1500px]:col-span-3"
        size="large"
        sizing="md"
        hideIcon
      />
      <div className="w-80 self-stretch pr-4 rounded-lg flex-col justify-start items-start gap-2.5 inline-flex col-span-2 max-[1500px]:col-span-3 ">
        <div className="self-stretch justify-start items-center gap-1 inline-flex group">
          <SelectField
            allowClear={false}
            label="Repayment Plan for Simulation"
            name={`${fieldPrefix}.assignedRepaymentPlanType`}
            styles={{
              control: {
                padding: `0px`,
              },
              value: {
                color: '#111928',
              },
            }}
            removeErrorPlaceholder={true}
          >
            {loan.eligibleRepaymentPlanTypes?.map((x: string) => (
              <option key={x} value={x}>
                {renderRepaymentPlanTypeAcronym(x)}
              </option>
            ))}
          </SelectField>
          <Button
            color="light"
            size="small"
            className="py-[6px] px-3 hidden group-hover:block"
            onClick={() => {
              form.values[prefix]?.forEach((l, index) => {
                if (l.eligibleRepaymentPlanTypes.includes(loan.assignedRepaymentPlanType))
                  form.setFieldValue(
                    `${prefix}[${index}].assignedRepaymentPlanType`,
                    loan.assignedRepaymentPlanType
                  );
              });
            }}
          >
            All
          </Button>
        </div>
      </div>
      {(isTimeBasedForgivnessActive || isPslfActive) && (
        <div className="pr-2 -mt-1 flex-col justify-start items-start gap-2.5 inline-flex col-span-1 ">
          <div className="self-stretch justify-start items-start gap-0.5 inline-flex">
            <div className="text-gray-500 text-xs font-medium">{isPslfActive ? `PSLF` : `IDR`}</div>
          </div>

          <div className="self-stretch justify-start items-start gap-1 inline-flex group w-[120px]">
            <TextInputField
              type="number"
              placeholder=""
              name={
                isPslfActive ? `${fieldPrefix}.pslfCount` : `${fieldPrefix}.idrMonthsInRepayment`
              }
              className="w-16"
              style={{ padding: 6 }}
            />
            <Button
              color="light"
              size="small"
              className="py-[6px] px-3  hidden group-hover:block"
              onClick={() => {
                form.values[prefix]?.forEach((l, index) => {
                  const value = isPslfActive ? loan.pslfCount : loan.idrMonthsInRepayment;

                  const field = isPslfActive
                    ? `${prefix}[${index}].pslfCount`
                    : `${prefix}[${index}].idrMonthsInRepayment`;

                  form.setFieldValue(field, value);
                });
              }}
            >
              All
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const AdditionalAlertContent = () => {
  return (
    <div className="flex flex-col gap-2 mt-4">
      <div>
        <span className="text-blue-800 text-sm font-bold">Graduate Loan: </span>
        <span className="text-blue-800 text-sm font-normal">
          Select all Graduate loans prior to running the SAVE Plan Simulation.
        </span>
      </div>

      <div>
        <span className="text-blue-800 text-sm font-bold">IDR: </span>
        <span className="text-blue-800 text-sm font-normal">
          Enter number of months in repayment for IDR Plan.
        </span>
      </div>
      <div>
        <span className="text-blue-800 text-sm font-bold">PSLF: </span>
        <span className="text-blue-800 text-sm font-normal">
          Enter number of months in repayment for PSLF Plan.
        </span>
      </div>
    </div>
  );
};

export const FederalLoanSummaryForm = ({ clientName }: Props) => {
  const { values } = useFormikContext<CalculatorFormValues>();

  const [dismissAlert, setDismissAlert] = useState(false);
  const [showAllLoans, setShowAllLoans] = useState(false);
  let loans = values.loans;

  if (!showAllLoans) {
    loans = loans.slice(0, 3);
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="text-gray-900 text-2xl font-bold">Federal Loans</div>
      {!dismissAlert && (
        <Alert
          icon={HiInformationCircle}
          color="info"
          additionalContent={<AdditionalAlertContent />}
          rounded
          onDismiss={() => setDismissAlert(true)}
        >
          How to get the best results?
        </Alert>
      )}
      <div className="pb-4 rounded-lg border border-gray-200 flex-col justify-start items-start gap-2 inline-flex">
        <div className="flex border border-gray-200 border-t-0 border-l-0 border-r-0 min-w-full">
          <div
            className={`bg-gray-100 text-primary-900 w-full h-14 p-4  flex-col justify-center items-center inline-flex cursor-pointer`}
          >
            <div className="self-stretch justify-center items-center inline-flex">
              <div className="text-sm font-semibold">{clientName} Loans</div>
            </div>
          </div>
        </div>

        {loans.length ? (
          loans.map((loan: CalculatorFederalLoanFormValue, index: number) => (
            <FederalLoanItem
              key={loan.id}
              loan={loan}
              prefix={'loans'}
              fieldPrefix={`loans[${index}]`}
            />
          ))
        ) : (
          <div className="flex justify-center items-center w-full h-20 text-gray-500">
            No loans were uploaded/added. Please upload the NSLDS file or add the loans manually.
          </div>
        )}
        {values.loans.length > 3 && (
          <Button
            color="light"
            className="mt-2 ml-4"
            onClick={() => setShowAllLoans(!showAllLoans)}
          >
            {showAllLoans ? `Collapse` : `Show All`}
          </Button>
        )}
      </div>
    </div>
  );
};
