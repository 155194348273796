import { FaVideo, FaFileAlt, FaUsers, FaQuestionCircle, FaExternalLinkAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import { PageHeader } from '../../components/PageHeader';

const Card = ({
  icon,
  title,
  description,
  linkText,
  linkHref,
  externalLinkicon,
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
  linkText: string;
  linkHref: string;
  externalLinkicon?: boolean;
}) => {
  const navigate = useNavigate();
  const isExternalLink = externalLinkicon || linkHref.startsWith('http');

  const handleClick = () => {
    if (!isExternalLink) {
      navigate(linkHref);
    } else {
      window.open(linkHref, '_blank');
    }
  };

  return (
    <div
      className="bg-white shadow-md rounded-lg p-6 text-start cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex justify-start mb-4 bg-primary-100 max-w-fit rounded-lg">
        <span className="text-blue-500 text-4xl p-3 ">{icon}</span>
      </div>
      <h3 className="text-xl font-semibold">{title}</h3>
      <p className="text-gray-600 my-4 ">{description}</p>
      {isExternalLink ? (
        <a
          href={linkHref}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 flex gap-1 items-center"
        >
          {externalLinkicon && <FaExternalLinkAlt />} {linkText}
        </a>
      ) : (
        <Link to={linkHref} className="text-blue-500 flex gap-1 items-center">
          {linkText}
        </Link>
      )}
    </div>
  );
};

export const Resources = () => {
  const cardsData = [
    {
      icon: <FaVideo />,
      title: 'Videos and Downloads',
      description: 'Step-by-step video tutorials to learn more about the Liability Planning tools.',
      linkText: 'See more',
      linkHref: '/resources/videos-and-downloads',
    },
    {
      icon: <FaFileAlt />,
      title: 'Assumptions',
      description: "View the underlying details of Finology Software's Liability Planner.",
      linkText: 'See more',
      linkHref: '/resources/assumptions',
    },
    {
      icon: <FaUsers />,
      title: 'Partners',
      description: 'Learn more about the growing platform connections.',
      linkText: 'See more',
      linkHref: '/resources/partners',
    },
    {
      icon: <FaQuestionCircle />,
      title: 'FAQ',
      description: 'Have a question? Dive deeper with the Finology Software FAQ.',
      linkText: 'Go to FAQ page',
      linkHref: 'https://finology.tech/resources/',
      externalLinkicon: true,
    },
  ];

  return (
    <>
      <PageHeader breadcrumbs={[{ to: '/resources', text: 'Resources' }]} />
      <div className="text-center mb-10">
        <h2 className="text-3xl font-bold">Learn more about the Finology software</h2>
        <p className="text-gray-600">
          View information on the Liability Planner tools with a helpful knowledge base.
        </p>
      </div>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {cardsData.map((card, index) => (
          <Card
            key={index}
            icon={card.icon}
            title={card.title}
            description={card.description}
            linkText={card.linkText}
            linkHref={card.linkHref}
            externalLinkicon={card.externalLinkicon}
          />
        ))}
      </div>
    </>
  );
};
