import { ApolloProvider } from '@apollo/client';
import { Flowbite, Spinner } from 'flowbite-react';
import { useEffect } from 'react';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { apolloClient } from './bootstrap/apollo';
import { CalculatorSearchClient } from './components/calculator/CalculatorSearchClient';
import { FlyoutContext } from './components/flyout/Flyout';
import { getBackUrl, getSearchParams } from './components/flyout/Flyout.utils';
import { NoSubscriptionHandler } from './components/subscirption/NoSubscriptionHandler';
import { REGISTRATION_PROCESS_LOCAL_STORAGE_ID } from './constants';
import { AppContextProvider, useGlobalState } from './context/AppContext';
import { ComparisonToolFlyout } from './features/calculator/comparison/ComparisonTool';
import { SimulationResultsFlyout } from './features/calculator/results/SimulationResult';
import { AddClientFlyoutSimple } from './features/clients/flyouts/AddClientFlyoutSimple';
import { UpsertCreditCardFlyout } from './features/clients/flyouts/creditCards/UpsertCreditCardFlyout';
import { InviteClientFlyout } from './features/clients/flyouts/InviteClientFlyout';
import { UpsertPrivateLoanFlyout } from './features/clients/flyouts/loans/UpsertPrivateLoanFlyout';
import { PickSpouseFlyout } from './features/clients/flyouts/PickSpouseFlyout';
import { UploadNsldsFlyout } from './features/clients/flyouts/UploadNsldsFlyout';
import { UpsertClientFlyout } from './features/clients/flyouts/UpsertClientFlyout';
import { UpsertFederalLoanFlyout } from './features/clients/flyouts/UpsertFederalLoanFlyout';
import { ClientFederalLoanVersionFlyout } from './features/clients/flyouts/versions/ClientFederalLoanVersion';
import { ClientFederalLoanVersions } from './features/clients/flyouts/versions/ClientFederalLoanVersions';
import { RepaymentPdfsFlyout } from './features/clients/repayment-pdfs/RepaymentPdfs';
import { EditCompanyFlyout } from './features/company/Company';
import { Header } from './features/header/Header';
import { SideNavigation } from './features/navigation/SideNavigation';
import { ClientProfileFlyout, ProfileFlyout } from './features/profile/Profile';
import { Assumptions } from './features/resources/Assumptions';
import { Partners } from './features/resources/Partners';
import { Resources } from './features/resources/Resources';
import { VideosAndDownloads } from './features/resources/VideosAndDownloads';
import { InviteUserFlyout } from './features/users/flyouts/InviteUserFlyout';
import { ManageAdvisorAssistantFlyout } from './features/users/flyouts/ManageAdvisorAssistantFlyout';
import { useGtag } from './hooks/useGtag';
import { useHubspot } from './hooks/useHubspot';
import { useInputWheelEventListener } from './hooks/useInputWheelEventListener';
import { initializePendoData, usePendoTracking } from './hooks/usePendo';
import { AdvisorApplicationsPage } from './pages/borrowers/AdvisorApplicationsPage';
import { CalculatorPage } from './pages/Calculator';
import { Client } from './pages/Client';
import { Clients } from './pages/Clients';
import { CompanyPage } from './pages/CompanyPage';
import { Dashboard } from './pages/Dashboard';
import { Demo } from './pages/Demo';
import { ProfilePage } from './pages/ProfilePage';
import { RepaymentPdfsPage } from './pages/RepaymentPdfs';
import { SettingsPage } from './pages/SettingsPage';
import { SuperAdminClientsPage } from './pages/super-admin/SuperAdminClientsPage';
import { SuperAdminDashboardPage } from './pages/super-admin/SuperAdminDashboardPage';
import { SuperAdminUsersPage } from './pages/super-admin/SuperAdminUsersPage';
import { UsersPage } from './pages/UsersPage';
import { WelcomePage } from './pages/WelcomePage';
import { isClient } from './utils';

import type { CustomFlowbiteTheme } from 'flowbite-react';

const Flyouts = () => {
  return (
    <>
      <UpsertClientFlyout />
      <UploadNsldsFlyout />
      <AddClientFlyoutSimple />
      <UpsertPrivateLoanFlyout />
      <InviteUserFlyout />
      <ManageAdvisorAssistantFlyout />
      <ProfileFlyout />
      <ClientFederalLoanVersions />
      <ClientFederalLoanVersionFlyout />
      <EditCompanyFlyout />
      <UpsertFederalLoanFlyout />
      <InviteClientFlyout />
      <RepaymentPdfsFlyout />
      <ClientProfileFlyout />
      <PickSpouseFlyout />
      <UpsertCreditCardFlyout />
      <SimulationResultsFlyout />
      <ComparisonToolFlyout />
    </>
  );
};

const EnsureAuthenticatedUser = () => {
  const { user } = useGlobalState();

  if (user === undefined || user == null) {
    return (
      <div className="h-screen v-screen flex justify-center items-center">
        <Spinner size={'xl'}></Spinner>
      </div>
    );
  }

  return <Outlet />;
};

const RequireFirstLoginOnly = () => {
  const isFirstLogin = sessionStorage.getItem(REGISTRATION_PROCESS_LOCAL_STORAGE_ID) === 'true';
  if (!isFirstLogin) return <Navigate to="/dashboard" />;
  return <Outlet />;
};

const AppWithLoadedUser = () => {
  const location = useLocation();
  const { user } = useGlobalState();
  const isFirstLogin = sessionStorage.getItem(REGISTRATION_PROCESS_LOCAL_STORAGE_ID) === 'true';

  initializePendoData();

  if (
    !user!.hasActiveSubscription &&
    location.pathname !== '/dashboard' &&
    !user?.roles?.includes('Borrower') &&
    !isFirstLogin
  ) {
    return <Navigate to="/dashboard" />;
  }

  if (isFirstLogin && location.pathname !== '/welcome-page') return <Navigate to="/welcome-page" />;

  return (
    <>
      <div data-testid="flyout-root" id="flyout-root"></div>

      <div className="flex">
        <SideNavigation />

        <div className="flex flex-col grow print:ml-0">
          <Header />
          <div className="py-6 px-6">
            {!isClient(user) && !user!.hasActiveSubscription ? (
              <NoSubscriptionHandler />
            ) : (
              <Outlet />
            )}
          </div>
        </div>
      </div>

      <Flyouts />
    </>
  );
};

const BrowsableApp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useLocation();
  const params = getSearchParams(search);

  useEffect(() => {
    if (location.pathname === '/first-signin') {
      sessionStorage.setItem(REGISTRATION_PROCESS_LOCAL_STORAGE_ID, 'true');
    }
  }, []);

  return (
    <FlyoutContext.Provider
      value={{ closeTopFlyout: () => navigate(getBackUrl(search, params[0])) }}
    >
      <Routes>
        <Route element={<EnsureAuthenticatedUser />}>
          <Route element={<AppWithLoadedUser />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/pdfs" element={<RepaymentPdfsPage />} />
            <Route path="/advisor-applications" element={<AdvisorApplicationsPage />} />

            <Route element={<RequireFirstLoginOnly />}>
              <Route path="/welcome-page" element={<WelcomePage />} />
            </Route>
            <Route path="/dashboard/clients/:clientId" element={<Client />} />
            <Route path="/dashboard/clients/:clientId/calculator" element={<CalculatorPage />} />
            <Route
              path="/dashboard/clients/:clientId/calculator/result"
              element={<CalculatorPage />}
            />
            <Route path="/demo-video" element={<Demo />} />

            <Route path="/clients" element={<Clients />} />
            <Route path="/clients/:clientId" element={<Client />} />
            <Route path="/clients/:clientId/calculator" element={<CalculatorPage />} />

            <Route path="/calculator/:clientId" element={<CalculatorPage />} />
            <Route path="/calculator" element={<CalculatorSearchClient />} />

            <Route path="/resources" element={<Resources />} />
            <Route path="/resources/videos-and-downloads" element={<VideosAndDownloads />} />
            <Route path="/resources/partners" element={<Partners />} />
            <Route path="/resources/assumptions" element={<Assumptions />} />

            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/settings/profile" element={<ProfilePage />} />

            {/* ONLY ADMIN  */}
            <Route path="/settings/company" element={<CompanyPage />} />
            <Route path="/settings/users" element={<UsersPage />} />

            <Route path="/super-admin/dashboard" element={<SuperAdminDashboardPage />} />
            <Route path="/super-admin/advisors" element={<SuperAdminUsersPage />} />
            <Route path="/super-admin/clients" element={<SuperAdminClientsPage />} />

            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Route>
        </Route>
      </Routes>
    </FlyoutContext.Provider>
  );
};

const theme: CustomFlowbiteTheme = {
  alert: {
    base: 'flex flex-col gap-2 p-4 text-sm',
    borderAccent: 'border-t-4',
    closeButton: {
      base: '-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg p-1.5 focus:ring-2',
      icon: 'w-5 h-5',
      color: {
        info: 'bg-primary-50 text-primary-800 hover:text-primary-100',
      },
    },
    color: {
      info: 'text-primary-800 bg-primary-50 border-primary-800',
    },
    icon: 'mr-3 inline h-5 w-5 flex-shrink-0',
    rounded: 'rounded-lg',
    wrapper: 'flex items-center',
  },
  badge: {
    root: {
      color: {
        primary: 'bg-primary text-white',
        orange: 'bg-orange-200 text-orange-700',
      },
    },
  },
  accordion: {
    title: {
      flush: {
        off: '',
        on: '',
      },
      base: 'bg-gray-100 w-full flex items-center justify-between p-5 text-md font-medium',
    },
  },
  table: {
    root: {
      base: 'w-full text-left text-base text-gray-500',
    },
    head: {
      base: 'group/head text-xs text-gray-700',
    },
  },
  modal: {
    body: {
      base: 'px-10 py-6 flex-1 overflow-auto',
    },
    header: {
      base: 'flex items-start justify-between rounded-t border-b px-10 py-6 ',
    },
    footer: {
      base: 'flex items-center space-x-2 rounded-b border-gray-200 px-10 py-6',
    },
  },
  label: {
    root: {
      colors: {
        default: 'text-gray-500',
      },
    },
  },
  button: {
    color: {
      info: 'text-white bg-primary border border-transparent enabled:hover:bg-primary-800 focus:ring-4 focus:ring-primary-300',
      primary: 'bg-primary hover:bg-opacity-90 text-white',
      secondaryLight:
        'flex items-center justify-center gap-4 bg-primary-50 border-blue-500 border text-primary-700 hover:opacity-70 ',
      secondary:
        'text-white bg-cyan-700 border border-transparent enabled:hover:bg-cyan-800 focus:ring-4 focus:ring-cyan-300 dark:bg-cyan-600 dark:enabled:hover:bg-cyan-700 dark:focus:ring-cyan-800',
      primaryLight:
        'bg-primary-50 rounded-lg border border-primary-300 inline-flex text-primary-900',
      gray: `bg-gray-300 border border-gray-200 enabled:hover:bg-gray-400 focus:ring-4 focus:ring-gray-300 text-gray-900 rounded`,
    },
  },
  card: {
    root: {
      children: 'flex h-full flex-col gap-4 p-6',
    },
  },
  checkbox: {
    root: {
      base: 'h-4 w-4 rounded border border-gray-300 bg-gray-100 focus:ring-2 focus:ring-primary-500 text-primary-600',
    },
  },
  toggleSwitch: {
    toggle: {
      //TODO - fix when upgrade after 0.9
      base: 'rounded-full border after:rounded-full after:bg-white',
      checked: {
        color: {
          primary: 'bg-primary',
        },
      },
    },
  },
  navbar: {
    root: {
      base: 'min-h-[80px] flex px-6 print:hidden',
      inner: {
        base: 'flex grow items-center justify-between',
      },
    },
  },

  pagination: {
    pages: {
      selector: {
        active: 'bg-primary-100 text-primary-600 hover:bg-primary-100 hover:text-primary-700',
      },
    },
  },
  tabs: {
    tablist: {
      tabitem: {
        base: 'flex items-center justify-center mx-4 rounded-t-lg text-base font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 focus:outline-none pb-4',
        styles: {
          underline: {
            active: {
              on: 'text-primary border-primary-600 border-b-2',
            },
          },
        },
      },
    },
  },
  spinner: {
    color: {
      info: 'fill-primary-600',
    },
  },
  sidebar: {
    root: {
      base: 'h-screen text-neutral-100 px-3 py-4 bg-primary-900 print:hidden',
      inner: 'flex flex-col h-full bg-primary-900',
      collapsed: {
        on: 'min-w-16',
        off: 'min-w-64',
      },
    },
    logo: {
      base: 'h-8 justify-start items-start inline-flex pl-2 text-2xl',
    },
    itemGroup: {
      base: 'py-6',
    },
    item: {
      icon: {
        base: 'h-6 w-6 flex-shrink-0 text-white transition duration-75 group-hover:text-gray-900',
        active: 'text-white',
      },
      active: 'bg-primary-50 bg-opacity-30',
      base: 'flex items-center justify-center rounded-lg mb-2 p-2 text-base font-medium hover:bg-primary-50 hover:bg-opacity-20',
      content: {
        base: 'px-3 flex-1 ',
      },
    },
  },
};

export const App = () => {
  useHubspot();
  useGtag();
  useInputWheelEventListener();
  usePendoTracking();

  return (
    <Flowbite theme={{ theme }}>
      <ToastContainer />
      <ApolloProvider client={apolloClient}>
        <AppContextProvider>
          <BrowserRouter>
            <BrowsableApp />
          </BrowserRouter>
        </AppContextProvider>
      </ApolloProvider>
    </Flowbite>
  );
};

export default App;
