import { PrivateLoanType, PrivateLoanView } from '../graphql/generated';

export const getLoanAmount = (loans: PrivateLoanView[] | PrivateLoanView) => {
  const itemSum = (loan: PrivateLoanView) => loan.amount;

  return Array.isArray(loans)
    ? loans.reduce((prev, current) => prev + itemSum(current), 0)
    : itemSum(loans);
};

export const getLoanMonthlyPayment = (loans: PrivateLoanView[] | PrivateLoanView) => {
  const itemSum = (loan: PrivateLoanView) => loan.monthlyPayment ?? 0;

  return Array.isArray(loans)
    ? loans.reduce((prev, current) => prev + itemSum(current), 0)
    : itemSum(loans);
};

export const getLoanExtraMonthlyPayout = (loans: PrivateLoanView[] | PrivateLoanView) => {
  const itemSum = (loan: PrivateLoanView) => loan.extraPayout ?? 0;

  return Array.isArray(loans)
    ? loans.reduce((prev, current) => prev + itemSum(current), 0)
    : itemSum(loans);
};

export const getLabelForType = (type?: PrivateLoanType) => {
  switch (type) {
    case PrivateLoanType.Auto:
      return 'Auto';

    case PrivateLoanType.CreditCard:
      return 'Credit Card';

    case PrivateLoanType.Mortgage:
      return 'Mortgage';

    case PrivateLoanType.Other:
      return 'Other';

    case PrivateLoanType.PersonalLoan:
      return 'Personal';

    case PrivateLoanType.StudentLoan:
      return 'Student';
  }

  return '';
};
