import { Alert, Button } from 'flowbite-react';
import { useState } from 'react';

import {
  CalculatorCreditCardLoanView,
  CalculatorPrivateLoanView,
} from '../../../graphql/generated';
import { toDollars } from '../../../util/currency.formatter';
import { toPercentage } from '../../../util/number.formatter';
import { getLabelForType } from '../../../util/privateLoans.utils';
import { CurrencyField } from '../../formComponents/CurrencyField';
import { DateField } from '../../formComponents/DateField';
import { TextInputField } from '../../formComponents/InputField';
import { PercentageField } from '../../formComponents/PercentageField';

interface Props {
  privateLoans: CalculatorPrivateLoanView[];
  clientName: string;
  creditCardLoans: CalculatorCreditCardLoanView[];
  initialData: any;
}

const getTagConfig = (creditCard: CalculatorCreditCardLoanView) => {
  if (creditCard.type?.toLowerCase() === 'closed') {
    return { tagColor: 'bg-red-100', tagLabel: 'closed', textColor: 'text-red-900' };
  }

  if (creditCard.type?.toLowerCase() === 'open') {
    return { tagColor: `bg-green-100`, tagLabel: 'open', textColor: 'text-green-900' };
  }

  return { tagColor: `bg-yellow-100`, tagLabel: 'revolving', textColor: 'text-yellow-900' };
};

const CreditCards = ({
  creditCard,
  prefix,
}: {
  creditCard: CalculatorCreditCardLoanView;
  prefix: string;
}) => {
  const { tagColor, tagLabel, textColor } = getTagConfig(creditCard);
  return (
    <div className="grid grid-cols-8 gap-y-6 gap-x-3 items-center mb-6 pb-4 px-6 border border-gray-200 border-x-0 border-t-0 border-b-1 max-[1025px]:flex max-[1025px]:flex-wrap max-[1025px]:flex-col">
      <div className="col-span-3 flex flex-col gap-2 py-6 ">
        <div className="flex items-center gap-3 ">
          <div
            className={`px-2.5 py-1 ${tagColor} rounded-md justify-start items-center gap-1 flex`}
          >
            <div className={`text-center text-md font-medium ${textColor}`}>
              {tagLabel.toUpperCase()}
            </div>
          </div>
          <div className="text-gray-900 text-base font-semibold">
            {creditCard.bankName} - #{creditCard.last4Digits}
          </div>
        </div>
      </div>
      <div className="col-span-3" />
      <div className="col-span-2 flex justify-end">
        <span className="text-gray-500 text-sm font-normal">
          Current Balance:{' '}
          <span className="text-gray-900">{toDollars(creditCard.outstandingBalance)}</span>
        </span>
      </div>
      <div className="col-span-1 ">
        <div className="text-xs text-gray-500">Min. Payment Amt.</div>
        <div>{creditCard.minimumPayments}</div>
      </div>
      <div className="col-span-1 ">
        <div className="text-xs text-gray-500">Purchase APR</div>
        <div>{toPercentage(creditCard.purchaseApr / 100)}</div>
      </div>
      <div className="col-span-1 ">
        <div className="text-xs text-gray-500">Balance APR</div>
        <div>{toPercentage(creditCard.balanceTransferApr / 100)}</div>
      </div>
      <div className="mt-1">
        <PercentageField
          label="New Balance APR"
          name={`${prefix}.newBalanceApr`}
          placeholder="00.00"
          sizing="sm"
        />
      </div>
      <TextInputField
        label="Payment Amount"
        name={`${prefix}.newPaymentAmount`}
        placeholder="0"
        type="number"
        sizing="sm"
      />
      <TextInputField
        label="Months to Pay"
        name={`${prefix}.newMonthsToPay`}
        placeholder="0"
        type="number"
        sizing="sm"
      />
      <div className="col-span-2 w-min mt-1">
        <PercentageField
          label="Balance Transfer Rate"
          name={`${prefix}.balanceTransferRate`}
          placeholder="00.00"
          sizing="sm"
        />
      </div>
    </div>
  );
};

const PrivateLoan = ({ loan, prefix }: { loan: CalculatorPrivateLoanView; prefix: string }) => {
  return (
    <div className="flex flex-wrap px-6 justify-between items-center mb-6 pb-4 px-6 border border-gray-200 border-x-0 border-t-0 border-b-1">
      <div className="min-w-fit flex flex-col gap-2">
        <div className="flex items-center gap-3 ">
          <div className="px-2.5 py-1 bg-yellow-100 rounded-md justify-start items-center gap-1 flex">
            <div className="text-center text-md font-medium">{getLabelForType(loan.type)}</div>
          </div>
          <div className="text-gray-900 text-base font-semibold">{loan.name}</div>
        </div>
        <div className="mt-1 ml-1">
          <span className="text-gray-500 text-sm font-normal">
            Current Balance: <span className="text-gray-900 pl-0.5">{toDollars(loan.amount)}</span>
          </span>
        </div>
      </div>

      <div className="col-span-1">
        <PercentageField placeholder="Interest Rate" name={`${prefix}.interestRate`} sizing="md" />
      </div>
      <div className="col-span-1">
        <DateField
          placeholder="Repayment Begin Date"
          name={`${prefix}.startDate`}
          size="large"
          sizing="md"
          hideIcon
        />
      </div>
      <div className="col-span-1">
        <CurrencyField
          placeholder="Monthly Payment"
          name={`${prefix}.monthlyPayment`}
          sizing="md"
        />
      </div>
    </div>
  );
};

export const PrivateLoanSummaryForm = ({ privateLoans, clientName, creditCardLoans }: Props) => {
  const [showAllLoans, setShowAllLoans] = useState(false);
  const [showAllCreditCards, setShowAllCreditCards] = useState(false);

  let loans = privateLoans;
  let creditCards = creditCardLoans;

  if (!showAllLoans) {
    loans = loans.slice(0, 3);
  }

  if (!showAllCreditCards) {
    creditCards = creditCards.slice(0, 3);
  }

  const [activeTab, setActiveTab] = useState(0);
  const activeTabStyle = 'bg-gray-100 text-primary-900';
  const inactiveTabStyle = 'text-gray-500';

  return (
    <div className="flex flex-col gap-4">
      <div className="text-gray-900 text-2xl font-bold">Private Loans</div>
      {loans.length === 0 && creditCards.length === 0 ? (
        <Alert
          color="info"
          className="mt-4"
          additionalContent={
            <>
              <div className="text-base font-semibold">{clientName} has no private loans</div>
              <div className="mt-4">
                Adding private loans will help you understand how private loans will affect{' '}
                {clientName}&apos;s financial situation.
              </div>
              <div className="mt-4">
                You can continue with the simulation without adding the private loans.
              </div>
            </>
          }
        ></Alert>
      ) : null}
      <div className="pb-4 rounded-lg border border-gray-200 flex-col justify-start items-start gap-2 inline-flex">
        <div className="flex border border-gray-200 border-t-0 border-l-0 border-r-0 min-w-full">
          {['Private Loans', 'Credit Cards'].map((name, index) => {
            return (
              <div
                key={index}
                onClick={() => setActiveTab(index)}
                className={`${
                  activeTab === index ? activeTabStyle : inactiveTabStyle
                } w-full h-14 p-4 flex-col justify-center items-center inline-flex cursor-pointer`}
              >
                <div className="self-stretch justify-center items-center inline-flex">
                  <div className="text-sm font-semibold">{name}</div>
                </div>
              </div>
            );
          })}
        </div>
        {activeTab == 0 ? (
          <>
            {loans.length ? (
              <>
                <div className="w-full">
                  {loans.map((loan, index) => (
                    <PrivateLoan key={index} loan={loan} prefix={`privateLoans[${index}]`} />
                  ))}
                </div>
                {!!(privateLoans.length > 3) && (
                  <Button
                    color="light"
                    className=" ml-4"
                    onClick={() => setShowAllLoans(!showAllLoans)}
                  >
                    {showAllLoans ? `Show Less` : `Show All Loans`}
                  </Button>
                )}
              </>
            ) : (
              <div className="flex justify-center items-center w-full h-20 text-gray-500">
                No loans were added.
              </div>
            )}
          </>
        ) : (
          <>
            {creditCards.length ? (
              <>
                <div className="w-full">
                  {creditCards.map((cc, index) => (
                    <CreditCards key={index} creditCard={cc} prefix={`creditCards[${index}]`} />
                  ))}
                </div>
                {!!(creditCardLoans.length > 3) && (
                  <Button
                    color="light"
                    className="ml-4"
                    onClick={() => setShowAllCreditCards(!showAllCreditCards)}
                  >
                    {showAllCreditCards ? `Collapse` : `Show All`}
                  </Button>
                )}
              </>
            ) : (
              <div className="flex justify-center items-center w-full h-20 text-gray-500">
                No loans were added.
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
