import { Button, Modal } from 'flowbite-react';
import { HiBadgeCheck } from 'react-icons/hi';

import { AccountType } from '../../auth';
import { useGlobalState } from '../../context/AppContext';

export const TrialExpiredModal = ({ onClick }: { onClick: () => void }) => {
  const { user } = useGlobalState();
  const modalBulletPoints = [
    {
      title: 'Scale with Finology Software',
      description: 'Take advantage of unlimited client accounts for advisors',
    },
    {
      title: 'Sophisticated Solution',
      description: 'Powerful tools within the Liability Planner',
    },
    {
      title: 'Easy to Use',
      description: "Elevate your firm's planning capabilities.",
    },
    {
      title: 'Turn Key Technology',
      description: 'Federal loan repayment planning for any size firm.',
    },
  ];

  const type = user?.accountType;

  return (
    <Modal show={true} className="bg-black bg-opacity-80">
      <div className=" border border-b border-gray-200 p-4 rounded-t-lg">
        <div className=" font-medium text-gray-900  text-lg ml-6">
          Your {type == AccountType.Organization ? '7-Days' : '1 Month'} Free Trial Has Ended
        </div>
      </div>
      <Modal.Body>
        <div className=" font-black text-2xl mb-7 leading-none">Get full Access to Finology</div>
        <div className="flex flex-col items-start justify-start gap-4 mb-2">
          {modalBulletPoints.map(({ title, description }) => (
            <div key={title} className="flex justify-start flex-row gap-2">
              <div className="mt-0.5">
                <HiBadgeCheck size={20} className=" text-primary-600" />
              </div>
              <div className="flex flex-col gap-2">
                <div className=" leading-none text-sm font-extrabold text-gray-800">{title}</div>
                <div className=" text-xs font-normal text-gray-600">{description}</div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="w-full">
        <Button className="w-full" onClick={onClick}>
          Add Payment Details
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
