import classNames from 'classnames';
import dayjs from 'dayjs';
import { Alert, Button, Spinner, Tooltip } from 'flowbite-react';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { HiInformationCircle, HiPlus } from 'react-icons/hi';
import { MdFileCopy, MdWarning, MdEdit } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { MultipleLineChart } from '../../../components/charts';
import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';
import { ForgivenessTypeLabel } from '../../../components/labels/ForgivenessTypeLabel';
import { TaxFilingTypeLabel } from '../../../components/labels/TaxFilingTypeLabel';
import {
  useCalculatorScenariosQuery,
  useGetScenarioComparisonLazyQuery,
  useInitialComparisonLazyQuery,
} from '../../../graphql/generated';
import { toDollars } from '../../../util/currency.formatter';
import { toDateShortString } from '../../../util/date.formatter';
import { toPercentage } from '../../../util/number.formatter';
import { formatCurrency } from '../../../utils';
import SelectField from '../../formComponents/SelectField';
import { convertFormDataToGqlSimulateData, convertScenarioJsonToFormData } from '../utils';

const ComparisonInputRow = ({
  label,
  value,
  isBestResult,
}: {
  label: string;
  value: React.ReactNode;
  isBestResult?: boolean;
}) => {
  return (
    <div className="self-stretch p-4 border border-gray-300 justify-center items-center grid grid-cols-2">
      <div className="text-gray-600 text-sm font-inter font-normal">{label}</div>
      <div className={'flex justify-center text-gray-900 font-semibold text-sm font-inter'}>
        <div
          className={classNames(
            'grow text-center',
            isBestResult ? 'max-w-[128px] rounded-lg bg-green-200 text-green-900' : ''
          )}
        >
          {value}
        </div>
      </div>
    </div>
  );
};

const ComparisonInputItem = ({
  headerTitle,
  data,
  otherData,
}: {
  headerTitle: React.ReactNode;
  data?: any;
  otherData?: any[];
}) => {
  const [isBest, setIsBest] = useState([false, false, false, false, false, false, false, false]);

  useEffect(() => {
    const items = otherData?.filter((x) => x);

    if (!items || !data) return;

    const shouldColorAsHighest = (propertyAccess: (item: any) => any) => {
      const value = propertyAccess(data);

      if (!value && items.every((x) => !propertyAccess(x))) return false;

      for (let i = 0; i < items!.length; i++) {
        if (propertyAccess(items[i]) > propertyAccess(data)) {
          return false;
        }
      }
      return true;
    };

    const shouldColorAsLowest = (propertyAccess: (item: any) => any) => {
      const value = propertyAccess(data);

      if (!value && items.every((x) => !propertyAccess(x))) return false;

      for (let i = 0; i < items.length; i++) {
        if (propertyAccess(items[i]) < value) {
          return false;
        }
      }
      return true;
    };

    const isLowestDateFn = (propertyAccess: (item: any) => any) => {
      const dateConverted = new Date(propertyAccess(data));
      for (let i = 0; i < items.length; i++) {
        if (new Date(propertyAccess(items[i])) < dateConverted) {
          return false;
        }
      }
      return true;
    };

    const isMonthlyPaymentLowest = shouldColorAsLowest((x) => x.monthlyPayments[0]);
    const isLowestPayoffDate = isLowestDateFn((x) => x.payoffDate);
    const isHighestForgivenAmount = shouldColorAsHighest((x) => x.forgivenessAmount);
    const isLowestSumOfPayments = shouldColorAsLowest((x) => x.sumOfPayments);
    const isLowestTaxesOwed = shouldColorAsLowest((x) => x.taxesOwed);
    const isLowestInterestRate = shouldColorAsLowest((x) => x.weightedAverageInterestRate);

    setIsBest([
      isMonthlyPaymentLowest,
      isLowestPayoffDate,
      isHighestForgivenAmount,
      isLowestSumOfPayments,
      isLowestTaxesOwed,
      isLowestInterestRate,
    ]);
  }, [data, otherData]);

  return (
    <div className="rounded-lg flex flex-col inline-flex">
      <div className="w-full px-2.5 py-4 bg-primary rounded-t-lg flex justify-center items-center gap-1">
        <div className="w-full text-white bg-primary font-semibold">{headerTitle}</div>
      </div>

      {data ? (
        <>
          <ComparisonInputRow
            label="Repayment Type"
            value={
              data.processedPlans.length == 1
                ? data.processedPlans[0]
                : `${data.processedPlans[0]} and ${data.processedPlans.length - 1} more`
            }
          ></ComparisonInputRow>
          <ComparisonInputRow
            isBestResult={isBest[0]}
            label="Year 1 Monthly Payment"
            value={toDollars(data.monthlyPayments[0])}
          ></ComparisonInputRow>
          <ComparisonInputRow
            label="Payoff Date"
            isBestResult={isBest[1]}
            value={toDateShortString(data.payoffDate)}
          ></ComparisonInputRow>
          <ComparisonInputRow
            label="Forgiveness Amount"
            isBestResult={isBest[2]}
            value={toDollars(data.forgivenessAmount)}
          ></ComparisonInputRow>
          <ComparisonInputRow
            label="Sum of Payments"
            isBestResult={isBest[3]}
            value={toDollars(data.sumOfPayments)}
          ></ComparisonInputRow>
          <ComparisonInputRow
            isBestResult={isBest[4]}
            label="Taxes Owed"
            value={toDollars(data.taxesOwed)}
          ></ComparisonInputRow>
          <ComparisonInputRow
            label="Interest Rate"
            isBestResult={isBest[5]}
            value={toPercentage(data.weightedAverageInterestRate)}
          ></ComparisonInputRow>
          <ComparisonInputRow
            label="Forgiveness Type"
            value={<ForgivenessTypeLabel forgivenessType={data.forgivenessType} />}
          ></ComparisonInputRow>
          <ComparisonInputRow
            label="Extra Payments"
            value={toDollars(data.extraPayments)}
          ></ComparisonInputRow>
          <ComparisonInputRow
            label="Filing Status"
            value={<TaxFilingTypeLabel taxFilingType={data.taxFilingType} />}
          ></ComparisonInputRow>
        </>
      ) : data === undefined ? (
        <div className="self-stretch flex justify-center py-20">
          <Spinner size={'xl'} />
        </div>
      ) : (
        <InvalidScenario />
      )}
    </div>
  );
};

const ScenarioSelector = ({
  scenarios,
  onSubmit,
}: {
  scenarios: any;
  onSubmit: (selectedScenario: any) => Promise<any>;
}) => {
  return (
    <div className="w-full h-full px-24 py-20 bg-gray-100 rounded-lg border border-gray-300 flex flex-col justify-center items-center gap-16 inline-flex">
      <div className="text-green-500 h-12 w-12 bg-gray-300 rounded-full justify-center items-center inline-flex">
        <MdFileCopy className="h-6 w-6" />
      </div>
      <div className="text-center text-gray-700 text-2xl font-semibold font-inter break-words">
        {scenarios.length ? 'Select Scenario' : 'You have no saved scenarios'}
      </div>
      {scenarios.length ? (
        <Formik
          onSubmit={async (values) => {
            const selectedScenario = scenarios.find((r: any) => r.id == values.selectedScenarioId);

            await onSubmit(selectedScenario);
          }}
          initialValues={{ selectedScenarioId: '' }}
        >
          <Form>
            <SelectField name="selectedScenarioId">
              {scenarios.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.name}{' '}
                </option>
              ))}
            </SelectField>
            <Button type="submit" color="green">
              <HiPlus /> &nbsp;Compare
            </Button>
          </Form>
        </Formik>
      ) : null}
    </div>
  );
};

const InvalidScenario = () => {
  return (
    <div className="w-full h-full px-24 py-20 bg-gray-100 border-t border-gray-300 flex flex-col justify-center items-center gap-16 inline-flex">
      <div className="text-orange-500 h-12 w-12 bg-gray-300 rounded-full justify-center items-center inline-flex">
        <MdWarning className="h-6 w-6" />
      </div>
      <p className="text-center text-gray-700 text-2xl font-semibold font-inter">
        We could not load your scenario.
      </p>
      <p className="text-center text-gray-700 text-2xl font-semibold font-inter">
        Please confirm that your scenario is runnable by the planner.
      </p>
    </div>
  );
};

export const ComparisonTool = () => {
  const params = useParams();
  const clientId = Number.parseInt(params.clientId!);
  const { data } = useCalculatorScenariosQuery({
    variables: {
      clientId: clientId,
    },
  });
  const { closeTopFlyout } = useFlyoutContext();

  const [fullName, setFullName] = useState('');
  const [alertDismissed, setIsAlertDismissed] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<any>();
  const [secondPlan, setSecondPlan] = useState<any>();
  const [thirdPlan, setThirdPlan] = useState<any>();
  const [initialGraphData, setInitialGraphData] = useState<any[]>();
  const [secondPlanGraphData, setSecondPlanGraphData] = useState<any[]>();
  const [thirdPlanGraphData, setThirdPlanGraphData] = useState<any[]>();

  const [getScenarioComparisonData] = useGetScenarioComparisonLazyQuery();
  const [getInitialPlanComparisonData] = useInitialComparisonLazyQuery();

  useEffect(() => {
    const load = async () => {
      const { data } = await getInitialPlanComparisonData({
        variables: {
          clientId: clientId,
        },
      });

      const resultData: any[] = [];

      setFullName(data!.client!.fullName);

      if (data?.currentPlanComparisonInformation) {
        setCurrentPlan({
          sumOfPayments: data?.currentPlanComparisonInformation?.sumOfPayments,
          monthlyPayments: data?.currentPlanComparisonInformation?.monthlyPayments,
          payoffDate: data?.currentPlanComparisonInformation?.payoffDate,
          forgivenessAmount: data?.currentPlanComparisonInformation?.forgivenessAmount,
          taxesOwed: data?.currentPlanComparisonInformation?.taxesOwed,
          forgivenessType: data?.currentPlanComparisonInformation?.forgivenessType,
          extraPayments: data?.currentPlanComparisonInformation?.extraPayments,
          taxFilingType: data?.currentPlanComparisonInformation?.taxFilingType,
          processedPlans: data?.currentPlanComparisonInformation?.processedPlans,
          weightedAverageInterestRate:
            data?.currentPlanComparisonInformation?.weightedAverageInterestRate,
        });

        data?.currentPlanComparisonInformation?.monthlyPayments.forEach((monthlyPayment) => {
          resultData.push({
            usd: monthlyPayment,
            name: 'NSLDS File',
          });
        });
      } else {
        setCurrentPlan(null);
      }

      setInitialGraphData(resultData);
    };

    load();
  }, []);

  return (
    <>
      <FlyoutHeader
        primaryButton={{
          children: 'Close',
          onClick: closeTopFlyout,
        }}
        label={`Comparison Tool for ${fullName}`}
      ></FlyoutHeader>

      {!alertDismissed && (
        <Alert
          icon={HiInformationCircle}
          color="info"
          additionalContent={
            <span>
              Select Scenario&apos;s to reference, the optimal aspects will be highlighted in green.
            </span>
          }
          rounded
          onDismiss={() => setIsAlertDismissed(true)}
        >
          <span className="font-semibold">
            Introducing the Federal Student Loan Repayment Plan Comparison Tool.
          </span>
        </Alert>
      )}
      <div className="grid grid-cols-3 gap-x-8 my-8">
        <ComparisonInputItem
          headerTitle={<div className="pl-2 w-full flex justify-between">NSLDS File</div>}
          data={currentPlan}
          otherData={[secondPlan, thirdPlan]}
        />
        {secondPlan ? (
          <ComparisonInputItem
            headerTitle={
              <div className="pl-2 w-full flex justify-between">
                <span>Saved Scenario - {secondPlan.name} </span>
                <Tooltip content="Replace Scenario">
                  <MdEdit
                    onClick={() => {
                      setSecondPlan(null);
                      setSecondPlanGraphData([]);
                    }}
                    size={20}
                    className="hover:cursor-pointer hover:text-primary"
                  />
                </Tooltip>
              </div>
            }
            data={secondPlan}
            otherData={[currentPlan, thirdPlan]}
          />
        ) : (
          <ScenarioSelector
            scenarios={data?.calculatorScenarios.filter((x) => x.id !== thirdPlan?.id) || []}
            onSubmit={async (selectedScenario) => {
              const scenarioData = convertScenarioJsonToFormData(selectedScenario.jsonData);

              const inputData = convertFormDataToGqlSimulateData(scenarioData);

              const { data } = await getScenarioComparisonData({
                variables: {
                  input: {
                    ...inputData,

                    id: clientId,
                    startIteratingFromRepaymentBeginDate: true,
                  },
                },
              });

              if (data?.scenarioComparisonInformation) {
                setSecondPlan({
                  id: selectedScenario.id,
                  name: selectedScenario.name,
                  sumOfPayments: data?.scenarioComparisonInformation?.sumOfPayments,
                  monthlyPayments: data?.scenarioComparisonInformation?.monthlyPayments,
                  payoffDate: data?.scenarioComparisonInformation?.payoffDate,
                  forgivenessAmount: data?.scenarioComparisonInformation?.forgivenessAmount,
                  taxesOwed: data?.scenarioComparisonInformation?.taxesOwed,
                  forgivenessType: data?.scenarioComparisonInformation?.forgivenessType,
                  extraPayments: data?.scenarioComparisonInformation?.extraPayments,
                  taxFilingType: data?.scenarioComparisonInformation?.taxFilingType,
                  processedPlans: data?.scenarioComparisonInformation?.processedPlans,
                  weightedAverageInterestRate:
                    data?.scenarioComparisonInformation?.weightedAverageInterestRate,
                });

                const resultData: any[] = [];

                data?.scenarioComparisonInformation?.monthlyPayments.forEach(
                  (monthlyPayment: any) => {
                    resultData.push({
                      usd: monthlyPayment,
                      name: `Saved Scenario - ${selectedScenario.name}`,
                    });
                  }
                );

                setSecondPlanGraphData(resultData);
              } else {
                setSecondPlan(null);
              }
            }}
          />
        )}
        {thirdPlan ? (
          <ComparisonInputItem
            headerTitle={
              <div className="pl-2 w-full flex justify-between">
                <span>Saved Scenario - {thirdPlan.name} </span>
                <Tooltip content="Replace Scenario">
                  <MdEdit
                    onClick={() => {
                      setThirdPlan(null);
                      setThirdPlanGraphData([]);
                    }}
                    size={20}
                    className="hover:cursor-pointer hover:text-primary"
                  />
                </Tooltip>
              </div>
            }
            data={thirdPlan}
            otherData={[currentPlan, secondPlan]}
          />
        ) : (
          <ScenarioSelector
            scenarios={data?.calculatorScenarios.filter((x) => x.id !== secondPlan?.id) || []}
            onSubmit={async (selectedScenario) => {
              const scenarioData = convertScenarioJsonToFormData(selectedScenario.jsonData);

              const inputData = convertFormDataToGqlSimulateData(scenarioData);

              const { data } = await getScenarioComparisonData({
                variables: {
                  input: {
                    ...inputData,

                    id: clientId,
                    startIteratingFromRepaymentBeginDate: true,
                  },
                },
              });

              if (data?.scenarioComparisonInformation) {
                setThirdPlan({
                  id: selectedScenario.id,
                  name: selectedScenario.name,
                  sumOfPayments: data?.scenarioComparisonInformation?.sumOfPayments,
                  monthlyPayments: data?.scenarioComparisonInformation?.monthlyPayments,
                  payoffDate: data?.scenarioComparisonInformation?.payoffDate,
                  forgivenessAmount: data?.scenarioComparisonInformation?.forgivenessAmount,
                  taxesOwed: data?.scenarioComparisonInformation?.taxesOwed,
                  forgivenessType: data?.scenarioComparisonInformation?.forgivenessType,
                  extraPayments: data?.scenarioComparisonInformation?.extraPayments,
                  taxFilingType: data?.scenarioComparisonInformation?.taxFilingType,
                  processedPlans: data?.scenarioComparisonInformation?.processedPlans,
                  weightedAverageInterestRate:
                    data?.scenarioComparisonInformation?.weightedAverageInterestRate,
                });

                const resultData: any[] = [];

                data?.scenarioComparisonInformation?.monthlyPayments.forEach(
                  (monthlyPayment: any) => {
                    resultData.push({
                      usd: monthlyPayment,
                      name: `Saved Scenario - ${selectedScenario.name}`,
                    });
                  }
                );

                setThirdPlanGraphData(resultData);
              } else {
                setThirdPlan(null);
              }
            }}
          />
        )}
      </div>
      {initialGraphData && (
        <>
          <div className="text-gray-900 text-lg font-semibold mb-8">Monthly Payment</div>
          <MultipleLineChart
            data={initialGraphData
              .concat(secondPlanGraphData || [])
              .concat(thirdPlanGraphData || [])
              .flat()}
            config={{
              yAxis: {
                label: {
                  formatter: (text: string) => formatCurrency(text),
                },
              },
              xAxis: {
                label: {
                  formatter: (d: any, c: any, index: any) => {
                    return dayjs().add(d, 'year').format('YYYY');
                  },
                },
              },
            }}
          ></MultipleLineChart>
        </>
      )}
    </>
  );
};

export const ComparisonToolFlyout = () => {
  return (
    <Flyout
      id={`comparison-tool`}
      requiredParams={['clientId']}
      size={'large'}
      content={<ComparisonTool />}
    />
  );
};
