import { Button } from 'flowbite-react';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthenticationService } from '../../auth';
import { useGlobalState } from '../../context/AppContext';

import { TrialExpiredModal } from './TrialExpiredModal';

export const NoSubscriptionHandler = () => {
  const { user } = useGlobalState();

  const hasCreatedSubscriptionAtLeastOnce = !!user?.subscriptionStatus;

  function redirectToSubscriptionCreate() {
    window.location.href = `${process.env.REACT_APP_INTERNAL_API_HOST}/Redirect/ToSubscribe`;
  }

  console.log(hasCreatedSubscriptionAtLeastOnce)

  useEffect(() => {
    const isOwner = user?.roles?.includes('Owner');

    if (!isOwner) return;

    if (!hasCreatedSubscriptionAtLeastOnce) return;

    redirectToSubscriptionCreate();
  }, [user]);

  if (user?.roles?.includes('Client')) return <Navigate to="/dashboard" />;

  if (user?.roles?.includes('Owner')) {
    if (hasCreatedSubscriptionAtLeastOnce) return null;

    return <TrialExpiredModal onClick={redirectToSubscriptionCreate} />;
  }

  return (
    <div className="fixed top-0 left-0 z-50 min-w-screen min-h-screen bg-red-400 min-w-full bg-white/75 p-24">
      <div className="flex flex-col items-center mt-16">
        <div className="bg-fino-sidebar p-8 rounded-full">
          {/* NEW DS */}
          {/* <WarningOutlined className="text-6xl text-white" /> */}
        </div>
        <div className="text-3xl mt-6">Uh oh! Your account is not up to date.</div>
        <div className="mt-20 font-normal">Please contact your administrator.</div>
        <div className="mt-8">
          <form action="get">
            <Button onClick={() => AuthenticationService.logout()}>Sign Out</Button>
          </form>
        </div>
      </div>
    </div>
  );
};
