import { Label, ToggleSwitch } from 'flowbite-react';
import { Field, useFormikContext } from 'formik';

const AntSwitch = ({ field, form, ...props }: any) => {
  return (
    <ToggleSwitch
      color="primary"
      id={field.name}
      checked={props.checked || !!field.value}
      disabled={props.disabled}
      sizing={props.sizing}
      onChange={(e) => {
        props.onChange && props.onChange(e);

        form.setFieldValue(props.firstFieldName, !e);
        form.setFieldValue(props.secondFieldName, e);
        form.setFieldValue(field.name, e);
      }}
    />
  );
};

export const SwitchDoubleField = ({
  name,
  secondFieldLabel,
  firstFieldLabel,
  firstFieldName,
  secondFieldName,
  className,
  checked,
  onChange,
  disabled,
  style,
  sizing = 'md',
}: {
  name: string;
  secondFieldLabel: string;
  firstFieldLabel: string;
  firstFieldName: string;
  secondFieldName: string;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e?: any) => void;
  sizing?: 'sm' | 'md' | 'lg';
  style?: React.CSSProperties;
}) => {
  const { values } = useFormikContext<any>();

  const isFirstActive = values[firstFieldName];
  return (
    <div className={className + ' truncate' + ' flex gap-2 items-center justify-center'}>
      <Label className={`${isFirstActive ? `text-primary-500 font-bold` : ``}`}>
        {firstFieldLabel}
      </Label>
      <Field
        name={name}
        firstFieldName={firstFieldName}
        secondFieldName={secondFieldName}
        component={AntSwitch}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        sizing={sizing}
        style={style}
      />
      <Label className={`${!isFirstActive ? `text-primary-500 font-bold` : ``}`}>
        {secondFieldLabel}
      </Label>
    </div>
  );
};
