import { Button, ButtonProps } from 'flowbite-react';
import { ReactNode } from 'react';

interface Props {
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  label: string | JSX.Element;
  rightContent?: ReactNode;
}

export const FlyoutHeader = ({ primaryButton, secondaryButton, label, rightContent }: Props) => {
  return (
    <div className={`mb-4`}>
      <div className="bg-white min-h-[80px] min-w-full flex items-center justify-between">
        <div className="text-2xl ">{label}</div>

        <div className="flex gap-4">
          {secondaryButton && <Button color="light" {...secondaryButton} />}
          {primaryButton && <Button type="submit" {...primaryButton} />}
          {rightContent}
        </div>
      </div>
    </div>
  );
};
