import { Badge, Dropdown, Tabs } from 'flowbite-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountType } from '../auth';
import { PageHeader } from '../components/PageHeader';
import { useGlobalState } from '../context/AppContext';
import { MyClients } from '../features/clients/MyClients';
import { MyProspects } from '../features/clients/MyProspects';
import { useGetClientAdvisorApplicationsCountLazyQuery } from '../graphql/generated';
import useFinologyTranslation from '../translations/useFinologyTranslation';

export const Clients = () => {
  const navigate = useNavigate();
  const { t } = useFinologyTranslation();
  const { user } = useGlobalState();
  const [getApplicationsCount, query] = useGetClientAdvisorApplicationsCountLazyQuery();

  const isCollege = user?.accountType == AccountType.College;

  useEffect(() => {
    if (isCollege) return;

    async function loadProspectsCount() {
      getApplicationsCount();
    }

    loadProspectsCount();
  }, []);

  return (
    <>
      <PageHeader
        rightContent={
          <Dropdown label={`Add New ${t('borrower.uppercase')}`}>
            <Dropdown.Item onClick={() => navigate('?flyout=create-client-simple')}>
              NSLDS Upload
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate('?flyout=upsert-client')}>
              Manual {t('borrower.uppercase')} Entry
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={() => navigate('?flyout=invite-client')}>
              Invite {t('borrower.uppercase')}
            </Dropdown.Item> */}
          </Dropdown>
        }
        breadcrumbs={[{ to: '/clients', text: `My ${t('borrowers.uppercase')}` }]}
      />
      {isCollege ? (
        <MyClients />
      ) : (
        <Tabs style="underline">
          <Tabs.Item title="Clients">
            <MyClients />
          </Tabs.Item>
          <Tabs.Item
            title={
              <div className="flex items-center gap-x-1">
                Prospects
                {query.data?.clientAdvisorApplicationsCount ? (
                  <Badge color={'primary'}>{query.data?.clientAdvisorApplicationsCount}</Badge>
                ) : null}
              </div>
            }
          >
            <MyProspects />
          </Tabs.Item>
        </Tabs>
      )}
    </>
  );
};
