import { Avatar, Button, Card, Modal, Spinner } from 'flowbite-react';
import { Form, Formik, FormikErrors } from 'formik';
import { ReactNode, useRef, useState } from 'react';
import { MdEdit } from 'react-icons/md';

import { AuthenticationService } from '../../auth';
import { Flyout, useFlyoutContext } from '../../components/flyout/Flyout';
import { FlyoutHeader } from '../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../components/forms/FormErrorNotification';
import { CropImage } from '../../components/images/CropImage';
import { useGlobalActions } from '../../context/AppContext';
import {
  useGetCompanyInformationQuery,
  useSaveCompanyInformationMutation,
  useUploadCompanyLogoMutation,
} from '../../graphql/generated';
import { useFlyoutNavigate } from '../../hooks/useFlyoutNavigate';
import useFinologyTranslation from '../../translations/useFinologyTranslation';
import { notification } from '../../util/notification.utils';
import { dataURLtoFile } from '../../utils';
import { TextInputField } from '../formComponents/InputField';

const LineItem = ({ left, right }: { left: ReactNode; right: ReactNode }) => {
  return (
    <div className="flex-col justify-start items-start flex">
      <div className="text-gray-900 text-base font-semibold">{left}</div>
      <div className="text-gray-500 text-xs font-medium">{right}</div>
    </div>
  );
};

const FlyoutContent = () => {
  const { data } = useGetCompanyInformationQuery();
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<string>();
  const [imageToUpload, setImageToUpload] = useState<string>();
  const [openCropModal, setOpenCropModal] = useState<boolean>(false);
  const [openEditLogoModal, setOpenEditLogoModal] = useState<boolean>(false);

  const [saveCompanyInformation] = useSaveCompanyInformationMutation();

  const [uploadLogoMutation] = useUploadCompanyLogoMutation();
  const actions = useGlobalActions();

  const { closeTopFlyout } = useFlyoutContext();

  if (!data?.tenant) return <Spinner />;

  const getModalContent = (onUpload: (imageToUpload: string) => void, logo?: string | null) => {
    return (
      <div className="flex center justify-center flex-columns">
        {imageToUpload || logo ? (
          <div className="flex flex-col items-center">
            <div onClick={() => inputRef!.current?.click()} className="cursor-pointer">
              {imageToUpload ? (
                <img width={480} height={300} src={imageToUpload} />
              ) : (
                <img width={480} height={300} src={logo!} />
              )}
            </div>
            {imageToUpload && (
              <div className="mt-4 gap-x-4 flex justify-between">
                <Button color="light" onClick={() => setImageToUpload(undefined)}>
                  Remove Photo
                </Button>
                <Button onClick={() => onUpload(imageToUpload)}>Save</Button>
              </div>
            )}
          </div>
        ) : (
          <div
            className="cursor-pointer bg-gray-300 flex flex-col items-center justify-center w-[250px] h-[250px]"
            onClick={() => inputRef!.current?.click()}
          >
            <span>Click here to</span>
            <span className="underline">upload from your computer</span>
          </div>
        )}
      </div>
    );
  };

  const { tenant } = data;

  return (
    <Formik
      initialValues={{
        name: tenant.name,
        contactEmail: tenant.contactEmail,
        logoUrl: tenant.logoUrl,
        website: tenant.website,
      }}
      onSubmit={async (v) => {
        await saveCompanyInformation({
          variables: {
            input: {
              name: v.name,
              contactEmail: v.contactEmail!,
              logoUrl: v.logoUrl,
              website: v.website,
            },
          },
          refetchQueries: ['GetCompanyInformation'],
        });

        notification.success({
          placement: 'bottom-center',
          message: 'Company information has been saved',
        });

        actions.setCompanyLogo(v.logoUrl);

        await AuthenticationService.updateUser({ company_logo: v.logoUrl });

        closeTopFlyout();
      }}
      validate={(values) => {
        const errors: FormikErrors<{ contactEmail: string; name: string; website: string }> = {};

        if (!values.contactEmail) {
          errors.contactEmail = 'Required';
        }

        if (values.name != undefined && values.name.length > 200) {
          errors.name = 'Name must be less than 200 characters';
        }

        const urlRegExp =
          /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:?#@!$&'()*+,;=%]*)?\/?$/;

        if (values.website != undefined && !urlRegExp.test(values.website)) {
          errors.website = 'Invalid URL';
        }

        return errors;
      }}
    >
      {({ values, setFieldValue, setFieldTouched, isSubmitting }) => {
        return (
          <>
            <FormErrorNotification />
            <FlyoutHeader
              primaryButton={{
                form: 'edit-company-form',
                children: 'Save',
                disabled: isSubmitting,
              }}
              secondaryButton={{
                onClick: closeTopFlyout,
                children: 'Cancel',
              }}
              label={'Edit'}
            />
            <Form id={'edit-company-form'}>
              <span className="text-primary">Logo</span>

              <div className="mb-4" onClick={() => setOpenEditLogoModal(true)}>
                {values.logoUrl ? <img src={values.logoUrl} width={96} /> : <Avatar />}
              </div>

              <Modal show={openEditLogoModal} onClose={() => setOpenEditLogoModal(false)}>
                <Modal.Header>Edit Company Logo</Modal.Header>
                <Modal.Body>
                  {getModalContent(async (image) => {
                    const newFile = dataURLtoFile(image, values.name + '-logo-image');

                    const { data } = await uploadLogoMutation({
                      variables: { file: newFile },
                      context: {
                        headers: {
                          'GraphQL-preflight': 1,
                        },
                      },
                    });

                    if (data) {
                      setFieldValue('logoUrl', data?.uploadTenantLogo!.logoUrl);

                      if (data?.uploadTenantLogo!.logoUrl) {
                        setOpenEditLogoModal(false);
                      }
                    }
                  }, values.logoUrl)}
                </Modal.Body>

                <Modal show={openCropModal} size={'5xl'} onClose={() => setOpenCropModal(false)}>
                  <Modal.Header>Crop Photo</Modal.Header>
                  <Modal.Body>
                    {' '}
                    <CropImage
                      aspect={16 / 9}
                      src={selectedImage}
                      onComplete={(image: string) => {
                        setImageToUpload(image);
                        setOpenCropModal(false);
                      }}
                    />
                  </Modal.Body>
                </Modal>

                <input
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  hidden
                  onChange={async (e) => {
                    setFieldTouched('logoUrl');

                    if (!e.target.files) return;

                    setSelectedImage(URL.createObjectURL(e.target.files[0]));
                    setOpenCropModal(true);
                  }}
                />
              </Modal>
              <TextInputField name="name" placeholder="Name"></TextInputField>
              <TextInputField
                type="email"
                name="contactEmail"
                placeholder="Contact Email"
              ></TextInputField>
              <TextInputField name="website" placeholder="Website"></TextInputField>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export const Company = () => {
  const { data: companyInformationQuery } = useGetCompanyInformationQuery();
  const { t } = useFinologyTranslation();
  const { navigate } = useFlyoutNavigate();

  return (
    <Card>
      <div className="flex justify-between items-start">
        <h1>{t('pages.settings.labels.tenant_title')}</h1>
        <Button color="light" onClick={() => navigate('edit-company')}>
          <MdEdit /> &nbsp; Edit
        </Button>
      </div>
      <div className="self-stretch justify-center items-center gap-4 inline-flex">
        <div className="rounded-[40px] border-4 border-white flex-col justify-center items-center inline-flex max-w-[160px]">
          <img
            src={
              companyInformationQuery?.tenant?.logoUrl
                ? companyInformationQuery!.tenant!.logoUrl
                : undefined
            }
          />
        </div>
        <div className="grow shrink basis-0 self-stretch flex-col justify-center items-start gap-4 inline-flex">
          <div className="flex-col justify-start items-start gap-1 flex">
            <div className="text-gray-900 text-2xl font-bold">
              {companyInformationQuery?.tenant?.name}
            </div>
            <div className="text-gray-500 text-base font-normal">
              {companyInformationQuery?.tenant?.contactEmail}
            </div>
          </div>
        </div>
      </div>
      <LineItem
        left={<div className="text-fino-grey">Website</div>}
        right={companyInformationQuery?.tenant?.website}
      ></LineItem>
    </Card>
  );
};

export const EditCompanyFlyout = () => {
  return <Flyout id={`edit-company`} size={'small'} content={<FlyoutContent />}></Flyout>;
};
