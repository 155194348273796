import { Avatar, Card } from 'flowbite-react';
import { ReactNode } from 'react';
import { MdEdit } from 'react-icons/md';

import { Flyout } from '../../components/flyout/Flyout';
import { AddressLabel } from '../../components/labels/AddressLabel';
import { FullNameLabel } from '../../components/labels/FullNameLabel';
import { useGlobalState } from '../../context/AppContext';
import {
  useGetUserQuery,
  MembershipAssociation,
  MethodOfCompensation,
  GetUserQuery,
} from '../../graphql/generated';
import { useFlyoutNavigate } from '../../hooks/useFlyoutNavigate';
import useFinologyTranslation from '../../translations/useFinologyTranslation';
import { isClient } from '../../utils';

import { ClientProfileFlyoutContent } from './ClientProfileFlyoutContent';
import { UserProfileFlyoutContent } from './UserProfileFlyoutContent';

const LineItem = ({ firstNode, secondNode }: { firstNode: ReactNode; secondNode: ReactNode }) => {
  return (
    <div className="flex flex-row justify-between items-center border-t-1 border-x-0 border-b-0 border border-gray-200 h-24 px-8">
      <div className="text-gray-600 ">{firstNode}</div>
      <div className=" text-black">{secondNode}</div>
    </div>
  );
};

export const Profile = () => {
  const { data: userProfileQuery } = useGetUserQuery();
  const { user } = useGlobalState();
  const { navigate } = useFlyoutNavigate();
  return (
    <Card className="card-no-padding">
      <>
        <LineItem
          firstNode={<h1>My Profile</h1>}
          secondNode={
            <div
              className=" text-blue-600 cursor-pointer flex items-center "
              color="light"
              onClick={() => navigate(isClient(user) ? 'edit-client-profile' : 'edit-profile')}
            >
              <MdEdit /> &nbsp; Edit profile
            </div>
          }
        />

        {isClient(user) ? (
          <LineItem
            firstNode={<div className="text-fino-grey">Password</div>}
            secondNode={'********'}
          ></LineItem>
        ) : (
          <AdvisorProfileCards user={userProfileQuery?.user} />
        )}
      </>
    </Card>
  );
};

const MembershipAssociationsLabel = ({
  membershipAssociations,
}: {
  membershipAssociations: MembershipAssociation[] | null | undefined;
}) => {
  if (!membershipAssociations || membershipAssociations.length == 0) return null;

  return (
    <>
      {' '}
      {membershipAssociations
        .map((r) => {
          switch (r) {
            case MembershipAssociation.AmericanCollegeOfFinancialServices:
              return 'American College of Financial Services';
            case MembershipAssociation.AmericanInstituteOfCertifiedPublicAccountants:
              return 'American Institute of Certified Public Accountants (AICPA)';
            case MembershipAssociation.AssociationOfFinancialCounselingPlanningEducation:
              return 'Association of Financial Counseling Planning Education (AFCPE)';
            case MembershipAssociation.CertifiedFinancialPlannerBoardOfStandards:
              return 'Certified Financial Planner Board of Standards (CFP Board)';
            case MembershipAssociation.CfaInstitute:
              return 'CFA Institute';
            case MembershipAssociation.FeeOnlyNetwork:
              return 'Fee Only Network';
            case MembershipAssociation.FinancialPlanningAssociation:
              return 'Financial Planning Association (FPA)';
            case MembershipAssociation.GarrettPlanningNetwork:
              return 'Garrett Planning Network';
            case MembershipAssociation.NationalAssociationofPersonalFinancialAdvisors:
              return 'National Association of Personal Financial Advisors (NAPFA)';
            case MembershipAssociation.XyPlanningNetwork:
              return 'XY Planning Network';
          }
        })
        .join(', ')}
    </>
  );
};

const AdvisorProfileCards = ({ user }: { user: GetUserQuery['user'] }) => {
  const { t } = useFinologyTranslation();
  return (
    <div className="">
      <LineItem
        firstNode={<div className="text-fino-grey">Profile Photo</div>}
        secondNode={<Avatar img={user?.profileImageUrl || ''} rounded size="lg" />}
      />
      <LineItem
        firstNode={<div className="text-fino-grey">Full Name</div>}
        secondNode={FullNameLabel({ firstName: user?.firstName, lastName: user?.lastName })}
      ></LineItem>
      <LineItem
        firstNode={<div className="text-fino-grey">Address</div>}
        secondNode={
          <AddressLabel
            street={user?.addressStreet}
            city={user?.addressCity}
            state={user?.addressState}
            zipCode={user?.addressZipCode}
          />
        }
      ></LineItem>
      <LineItem
        firstNode={<div className="text-fino-grey">Email</div>}
        secondNode={user?.email}
      ></LineItem>
      <LineItem
        firstNode={<div className="text-fino-grey">Phone</div>}
        secondNode={user?.phone}
      ></LineItem>
      <LineItem
        firstNode={<div className="text-fino-grey">Social Media</div>}
        secondNode={user?.socialMedia}
      ></LineItem>
      <LineItem
        firstNode={<div className="text-fino-grey">CFP Board Info</div>}
        secondNode={user?.cfpBoardInfo}
      ></LineItem>
    </div>
  );
};

const MethodsOfCompensationlabel = ({
  methodsOfCompensation,
}: {
  methodsOfCompensation: MethodOfCompensation[] | null | undefined;
}) => {
  if (!methodsOfCompensation || methodsOfCompensation.length == 0) return null;

  return (
    <>
      {' '}
      {methodsOfCompensation
        .map((r) => {
          switch (r) {
            case MethodOfCompensation.AssetsUnderManagement:
              return 'Assets Under Management (AUM)';
            case MethodOfCompensation.Commissions:
              return 'Commissions';
            case MethodOfCompensation.FeeBased:
              return 'Fee-Based';
            case MethodOfCompensation.FeeOnly:
              return 'Fee-Only';
            case MethodOfCompensation.FixedFee:
              return 'Fixed Fee';
            case MethodOfCompensation.FixedRetainerFee:
              return 'Fixed Retainer Fee';
            case MethodOfCompensation.FlatFee:
              return 'Flat Fee';
            case MethodOfCompensation.HourlyFee:
              return 'Hourly Fee';
            case MethodOfCompensation.RetainerFee:
              return 'Retainer Fee';
            case MethodOfCompensation.Subscription:
              return 'Subscription';
          }
        })
        .join(', ')}
    </>
  );
};

export const ProfileFlyout = () => {
  return (
    <Flyout id={`edit-profile`} size={'small'} content={<UserProfileFlyoutContent />}></Flyout>
  );
};

export const ClientProfileFlyout = () => {
  return (
    <Flyout
      id={`edit-client-profile`}
      size={'small'}
      content={<ClientProfileFlyoutContent />}
    ></Flyout>
  );
};
