import classNames from 'classnames';
import dayjs from 'dayjs';
import { Label } from 'flowbite-react';
import { Field, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { FaRegCalendarAlt } from 'react-icons/fa';

//TODO: Replace with below component once https://github.com/themesberg/flowbite-react/issues/1039 is resolved
// const AntDateComponent = ({ field, form, ...props }: any) => {
//   const error = (!!form.touched[field.name] || form.submitCount) && !!form.errors[field.name];

//   return (
//     <Datepicker
//       color={error ? 'failure' : undefined}
//       autoHide={false}
//       defaultDate={field.value ? field.value.toDate() : undefined}
//       onSelectedDateChanged={(e: any) => {
//         const val = e ? dayjs(e).utc() : null;

//         form.setFieldValue(field.name, val);
//       }}
//     ></Datepicker>
//   );
// };

const AntDateComponent = ({ field, form, size, hideIcon, sizing, ...props }: any) => {
  const error = !!form.errors[field.name];

  const className = size === 'small' ? 'w-44' : undefined;
  let fixedValueType = field.value as any;
  if (typeof fixedValueType === 'object') {
    fixedValueType = fixedValueType?.toDate();
  } else if (typeof fixedValueType === 'string') {
    fixedValueType = dayjs(fixedValueType)?.toDate();
  }

  return (
    <div className="flex items-center relative w-min">
      <DatePicker
        showYearDropdown
        showMonthDropdown
        placeholderText={props.placeholder}
        todayButton="Today"
        isClearable={props.allowClear === undefined ? true : props.allowClear}
        selected={fixedValueType ? fixedValueType : undefined}
        onChange={(e: any) => {
          const val = e ? dayjs(e).utc() : null;

          form.setFieldValue(field.name, val);
        }}
        showIcon
        calendarClassName="react-datepicker__navigation--years-upcoming "
        className={`${
          error ? classNames('react-datepicker-fino-error ', className) : className
        } ${sizing}`}
      ></DatePicker>
      {!hideIcon && !field.value && (
        <div className="absolute right-2 text-gray-500 p-3 bg-gray-50 z-10">
          <FaRegCalendarAlt height={24} width={24} />
        </div>
      )}
    </div>
  );
};

export const DateField = ({
  name,
  placeholder,
  className,
  allowClear,
  label,
  size = 'large',
  hideIcon,
  sizing,
}: {
  name: string;
  placeholder: string;
  className?: string;
  allowClear?: boolean;
  label?: string;
  size?: 'small' | 'large';
  hideIcon?: boolean;
  sizing?: `md` | `lg`;
}) => {
  const form = useFormikContext<any>();
  const error = form.errors[name];

  if (error != undefined && typeof error !== 'string' && typeof error !== 'number')
    throw new Error('Error must be of type string');

  return (
    <div className={classNames('flex flex-col', className)}>
      <Label
        color={error ? 'failure' : undefined}
        htmlFor={`form__field_${name}`}
        className="truncate"
      >
        <span>{label || placeholder}</span>
      </Label>
      <Field
        allowClear={allowClear}
        placeholder={placeholder}
        name={name}
        component={AntDateComponent}
        size={size}
        sizing={sizing}
        hideIcon={hideIcon}
      />
      {error && !error ? null : <p className=" text-sm text-red-700">{error} &nbsp;</p>}
    </div>
  );
};
