import { Card, Spinner } from 'flowbite-react';
import { useState } from 'react';
import { HiOutlineOfficeBuilding, HiUser, HiUsers, HiCreditCard } from 'react-icons/hi';

import { LinkCard } from '../../components/cards/Cards';
import { FinoLink } from '../../components/links/FinoLink';
import { useGlobalState } from '../../context/AppContext';
import {
  useCreateCustomerPortalLinkMutation,
  useGetSubscriptionInformationQuery,
} from '../../graphql/generated';
import useFinologyTranslation from '../../translations/useFinologyTranslation';
import { toDollarsWithDecimals } from '../../util/currency.formatter';
import { toDateWithTimeLongString } from '../../util/date.formatter';

function capitalizeFirstLetter(text?: string | null) {
  if (!text) return text;

  return text.charAt(0).toUpperCase() + text.slice(1);
}

const AccountAndBillingCard = () => {
  const [createCustomerPortalLink] = useCreateCustomerPortalLinkMutation();
  const { data, loading } = useGetSubscriptionInformationQuery();
  const [redirecting, setRedirecting] = useState(false);

  const createCustomerPortalLinkAndRedirect = async () => {
    setRedirecting(true);

    const { data } = await createCustomerPortalLink();

    window.open(data!.createCustomerPortalLink!);

    setRedirecting(false);
  };

  if (redirecting) return <Spinner />;

  const hasCreatedSubscriptionAtLeastOnce = !!data?.tenant.subscriptionInfo.subscriptionStatus;

  return (
    <Card>
      <div className="flex grow justify-between">
        <span className="font-bold">Account &amp; Billing</span>
        {hasCreatedSubscriptionAtLeastOnce ? (
          <FinoLink
            to={'#'}
            label={'Manage Subscription'}
            icon={<HiCreditCard />}
            onClick={createCustomerPortalLinkAndRedirect}
          />
        ) : null}
      </div>
      {loading ? (
        <Spinner />
      ) : data?.tenant == null ? null : (
        <>
          <div className="flex gap-1 flex-col pb-4 text-sm">
            <div className="flex justify-start">
              <div className="text-gray-500 min-w-[150px]">Current Plan:</div>
              <div>
                {hasCreatedSubscriptionAtLeastOnce ? 'Finology Yearly Subscription' : 'Free'}
              </div>
            </div>
            {hasCreatedSubscriptionAtLeastOnce ? (
              <>
                <div className="flex justify-start">
                  <div className="text-gray-500 min-w-[150px]">
                    {data!.tenant.subscriptionInfo.subscriptionStatus == 'trialing'
                      ? 'Bills'
                      : 'Renews'}{' '}
                    On:
                  </div>
                  <div>
                    {toDateWithTimeLongString(data!.tenant.subscriptionInfo.nextBillingAt) || 'N/A'}
                  </div>
                </div>
                <div className="flex justify-start">
                  <div className="text-gray-500 min-w-[150px]">Billing Amount:</div>
                  <div>
                    {data!.tenant.subscriptionInfo.nextBillingAmount
                      ? toDollarsWithDecimals(data!.tenant.subscriptionInfo.nextBillingAmount / 100)
                      : 'N/A'}
                  </div>
                </div>

                <div className="flex justify-start">
                  <div className="text-gray-500 min-w-[150px]">Status:</div>
                  <div>
                    {capitalizeFirstLetter(data!.tenant.subscriptionInfo.subscriptionStatus) ||
                      'N/A'}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </>
      )}
    </Card>
  );
};

export const Settings = () => {
  const { user } = useGlobalState();
  const { t } = useFinologyTranslation();

  return (
    <div className="flex flex-col gap-y-9">
      <LinkCard
        title="My Profile"
        text="Manage your personal information"
        to="/settings/profile"
        linkIcon={<HiUser />}
        linkText={'Manage Profile'}
      />
      {user?.roles?.includes('Owner') ? (
        <>
          <LinkCard
            title={t('pages.settings.labels.tenant_title')}
            text={t('pages.settings.labels.tenant_label')}
            to="/settings/company"
            linkIcon={<HiOutlineOfficeBuilding />}
            linkText={t('pages.settings.buttons.manage_tenant')}
          />
          <LinkCard
            title="User Management"
            text="Manage user accounts"
            to="/settings/users"
            linkIcon={<HiUsers />}
            linkText={'Manage Users'}
          />

          <AccountAndBillingCard />
        </>
      ) : null}
    </div>
  );
};
