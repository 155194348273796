import { useFormikContext } from 'formik';

import { useDebounceEffect } from '../../../components/images/useDebounceEffect';
import { useUpsertScenarioMutation } from '../../../graphql/generated';

import { CalculatorFormValues } from './EditCalculatorDetails';

export const ScenarioAutosaveComponent = () => {
  const formik = useFormikContext<CalculatorFormValues>();
  const [upsertScenario] = useUpsertScenarioMutation();

  useDebounceEffect(
    async () => {
      if (!formik.dirty || !formik.isValid) return;

      const values = formik.values;

      const {
        isProjectionsModalVisible,
        isAssumptionsModalVisible,
        isSimulationForClient,
        isSimulationForSpouse,
        ...cleanedValues
      } = values;

      //   await upsertScenario({
      //     variables: {
      //       input: {
      //         id: activeScenario!.id,
      //         clientId: values.id,
      //         name: activeScenario!.name,
      //         jsonData: JSON.stringify(cleanedValues),
      //       },
      //     },
      //     refetchQueries: ['CalculatorScenarios'],
      //   });
    },
    500,
    [formik.values, formik.dirty, formik.isValid, formik.resetForm]
  );

  return null;
};
