// eslint-disable

import dayjs from 'dayjs';
import { Accordion, Button } from 'flowbite-react';
import { FormikErrors, Form, Formik, FieldArray, FormikHelpers } from 'formik';
import { ReactNode, useEffect, useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../../components/forms/FormErrorNotification';
import {
  FederalLoanViewStatus,
  useFetchLoanDetailsLazyQuery,
  useUpsertFederalLoanMutation,
} from '../../../graphql/generated';
import { notification } from '../../../util/notification.utils';
import { CheckboxField } from '../../formComponents/CheckboxField';
import { CurrencyField } from '../../formComponents/CurrencyField';
import { DateField } from '../../formComponents/DateField';
import { TextInputField } from '../../formComponents/InputField';
import { PercentageField } from '../../formComponents/PercentageField';
import SelectField from '../../formComponents/SelectField';

const KnownLoanTypes = [
  'DIRECT CONSOLIDATED SUBSIDIZED',
  'DIRECT CONSOLIDATED UNSUBSIDIZED',
  'DIRECT PARENT PLUS',
  'DIRECT PARENT PLUS CONSOLIDATED',
  'DIRECT PLUS GRADUATE',
  'DIRECT STAFFORD SUBSIDIZED',
  'DIRECT STAFFORD SUBSIDIZED (SULA ELIGIBLE)',
  'DIRECT STAFFORD UNSUBSIDIZED',
  'FFEL CONSOLIDATED',
  'FFEL PARENT PLUS',
  'FFEL PARENT PLUS CONSOLIDATED',
  'FFEL PLUS GRADUATE',
  'FFEL STAFFORD SUBSIDIZED',
  'FFEL STAFFORD UNSUBSIDIZED',
];

const KnownRepaymentPlanTypes = [
  'CONSOLIDATED',
  'EXTENDED',
  'GRADUATED',
  'INCOME BASED',
  'INCOME CONTINGENT',
  'PAY AS YOU EARN',
  'REVISED PAY AS YOU EARN',
  'SAVE',
  'STANDARD',
];

const PanelHeader = ({ children }: { children: ReactNode }) => {
  return <span className="uppercase">{children}</span>;
};
export const UpsertFederalLoanForm = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const loanId = params.get('loanId');
  const clientId = params.get('clientId');
  const createNewVersion = params.get('createNewVersion');

  const parsedLoanId = loanId ? Number.parseInt(loanId) : null;
  const parsedClientId = clientId ? Number.parseInt(clientId) : null;
  const parsedCreateNewVersion = createNewVersion === 'true';

  const [initialValues, setInitialValues] = useState<any>({
    applyRepaymentPlanTypeToAllLoans: false,
    applyPslfCumulativeMatchedMonthsToAllLoans: false,
    statuses: [],
  });
  const [lazyQuery] = useFetchLoanDetailsLazyQuery();

  const [upsertLoan] = useUpsertFederalLoanMutation();

  const { closeTopFlyout } = useFlyoutContext();

  useEffect(() => {
    const load = async () => {
      if (parsedLoanId) {
        const { data } = await lazyQuery({ variables: { loanId: parsedLoanId } });

        if (!data || !data.federalLoan) return;

        const { federalLoan } = data;

        const loan = federalLoan as any;

        setInitialValues({
          id: loan.id,
          type: loan.type,
          amount: loan.amount,
          canceledAmount: loan.canceledAmount,
          canceledDate: loan.canceledDate ? dayjs.utc(loan.canceledDate) : null,
          disbursedAmount: loan.disbursedAmount,
          interestBalance: loan.interestBalance,
          interestBalanceAsOfDate: loan.interestBalanceAsOfDate
            ? dayjs.utc(loan.interestBalanceAsOfDate)
            : null,
          principalBalance: loan.principalBalance,
          principalBalanceAsOfDate: loan.principalBalanceAsOfDate
            ? dayjs.utc(loan.principalBalanceAsOfDate)
            : null,
          date: loan.date ? dayjs.utc(loan.date) : null,
          periodBeginDate: loan.periodBeginDate ? dayjs.utc(loan.periodBeginDate) : null,
          periodEndDate: loan.periodEndDate ? dayjs.utc(loan.periodEndDate) : null,
          repaymentBeginDate: loan.repaymentBeginDate ? dayjs.utc(loan.repaymentBeginDate) : null,
          interestRateType: loan.interestRateType,
          interestRate: Math.round(loan.interestRate * 10000) / 100,
          cumulativePaymentAmount: loan.cumulativePaymentAmount,
          mostRecentPaymentEffectiveDate: loan.mostRecentPaymentEffectiveDate
            ? dayjs.utc(loan.mostRecentPaymentEffectiveDate)
            : null,
          nextPaymentDueDate: loan.nextPaymentDueDate ? dayjs.utc(loan.nextPaymentDueDate) : null,
          pslfCumulativeMatchedMonths: loan.pslfCumulativeMatchedMonths || 0,
          reaffirmationDate: loan.reaffirmationDate ? dayjs.utc(loan.reaffirmationDate) : null,
          repaymentPlanType: loan.repaymentPlanType,
          repaymentPlanBeginDate: loan.repaymentPlanBeginDate
            ? dayjs.utc(loan.repaymentPlanBeginDate)
            : null,
          repaymentPlanScheduledAmount: loan.repaymentPlanScheduledAmount,
          repaymentPlanIdrPlanAnniversaryDate: loan.repaymentPlanIdrPlanAnniversaryDate
            ? dayjs.utc(loan.repaymentPlanIdrPlanAnniversaryDate)
            : null,
          attendingSchoolName: loan.attendingSchoolName,
          attendingSchoolOpeid: loan.attendingSchoolOpeid,
          awardId: loan.awardId,
          contactCity: loan.contactCity,
          contactEmail: loan.contactEmail,
          contactName: loan.contactName,
          contactPhoneExtension: loan.contactPhoneExtension,
          contactPhoneNumber: loan.contactPhoneNumber,
          contactStateCode: loan.contactStateCode,
          contactStreetAddress: loan.contactStreetAddress,
          contactStreetAddress2: loan.contactStreetAddress2,
          contactType: loan.contactType,
          contactZipCode: loan.contactZipCode,
          confirmedSubsidyStatus: loan.confirmedSubsidyStatus,
          subsidizedUsageInYears: loan.subsidizedUsageInYears,
          applyRepaymentPlanTypeToAllLoans: false,
          applyPslfCumulativeMatchedMonthsToAllLoans: false,
          statuses: loan.statuses.map((s: FederalLoanViewStatus) => ({
            status: s.status,
            description: s.description,
            effectiveDate: s.effectiveDate ? dayjs.utc(s.effectiveDate) : null,
          })),
        });
      }
    };

    load();
  }, [loanId]);

  return (
    <Formik
      validate={(values) => {
        const errors: FormikErrors<any> = {};
        if (!values.date) {
          errors.date = 'Required';
        }
        if (!values.type) {
          errors.type = 'Required';
        }
        if (values.interestBalance == null || values.interestBalance == undefined) {
          errors.interestBalance = 'Required';
        }
        if (values.principalBalance == null || values.principalBalance == undefined) {
          errors.principalBalance = 'Required';
        }
        if (values.amount == null || values.amount == undefined) {
          errors.amount = 'Required';
        }

        if (values.pslfCumulativeMatchedMonths && isNaN(values.pslfCumulativeMatchedMonths)) {
          errors.pslfCumulativeMatchedMonths = 'Must be a number';
        }

        if (values.pslfCumulativeMatchedMonths && values.pslfCumulativeMatchedMonths > 120) {
          errors.pslfCumulativeMatchedMonths = 'Must be less than 120';
        }

        if (
          values.reaffirmationDate &&
          values.date &&
          values.reaffirmationDate.isBefore(values.date)
        ) {
          errors.reaffirmationDate = 'Reaffirmation Date should not be before the loan date';
        }

        if (
          values.repaymentBeginDate &&
          values.periodEndDate &&
          values.repaymentBeginDate.isAfter(values.periodEndDate)
        ) {
          errors.repaymentBeginDate = 'Repayment Begin Date should not be after the End Date';
        }

        return errors;
      }}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (v: any, formikHelpers: FormikHelpers<any>) => {
        formikHelpers.setSubmitting(true);

        const input: any = {
          ...v,
          id: parsedLoanId,
          clientId: parsedClientId,
          pslfCumulativeMatchedMonths: v.pslfCumulativeMatchedMonths
            ? Number(v.pslfCumulativeMatchedMonths)
            : null,
          subsidizedUsageInYears: v.subsidizedUsageInYears
            ? Number(v.subsidizedUsageInYears)
            : null,
          interestRate:
            v.interestRate * 100 === initialValues.interestRate
              ? v.interestRate
              : v.interestRate / 100,
          createNewVersion: !parsedLoanId && parsedCreateNewVersion,
        };

        await upsertLoan({
          variables: { input: input },
          refetchQueries: [
            'GetFederalLoans',
            'GetClientDashboard',
            'GetClientFederalLoanVersions',
            'Calculator',
            'GetFederalLoanAggregations',
          ],
        });

        if (loanId)
          notification.success({
            message: 'Loan updated',
            placement: 'bottom-center',
          });
        else
          notification.success({
            message: 'Loan created',
            placement: 'bottom-center',
          });

        closeTopFlyout();
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => {
        return (
          <>
            <FormErrorNotification />
            <FlyoutHeader
              primaryButton={{
                form: 'upsert-federal-loan-form',
                children: 'Save',
                disabled: isSubmitting,
                id: 'upsert-federal-loan-button',
              }}
              secondaryButton={{
                onClick: closeTopFlyout,
                children: 'Cancel',
              }}
              label={loanId ? 'Edit Loan' : 'New Loan'}
            />

            <Form id="upsert-federal-loan-form" className="pb-16">
              <Accordion>
                <Accordion.Panel>
                  <Accordion.Title>
                    <PanelHeader>Balances</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div className="grid grid-cols-2 gap-y-2 gap-x-9 mt-4">
                      <CurrencyField
                        name="principalBalance"
                        placeholder="Outstanding Principal Balance"
                        sizing="md"
                        onChange={(e: any) =>
                          setFieldValue('amount', (e || 0) + Number(values.interestBalance || 0))
                        }
                      />
                      <DateField
                        sizing="md"
                        name="principalBalanceAsOfDate"
                        placeholder="Outstanding Principal Balance as of Date"
                      />

                      <CurrencyField
                        name="interestBalance"
                        sizing="md"
                        placeholder="Outstanding Interest Balance"
                        onChange={(e: any) => {
                          setFieldValue('amount', (e || 0) + Number(values.principalBalance || 0));
                        }}
                      />
                      <DateField
                        sizing="md"
                        name="interestBalanceAsOfDate"
                        placeholder="Outstanding Interest Balance as of Date"
                      />
                      <SelectField name="type" label="Type">
                        {KnownLoanTypes.map((t) => (
                          <option key={t} value={t}>
                            {t}{' '}
                          </option>
                        ))}
                        {!values.type || KnownLoanTypes.includes(values.type) ? null : (
                          <option key={values.type} value={values.type}>
                            {values.type}
                            {' * '}
                          </option>
                        )}
                      </SelectField>

                      <CurrencyField name="amount" placeholder="Current Amount" sizing="md" />
                    </div>

                    <div className="grid grid-cols-4 gap-y-2 gap-x-9 my-4 max-[1200px]:grid-cols-2">
                      <DateField name="date" placeholder="Loan Date" sizing="md" />
                      <CurrencyField
                        name="canceledAmount"
                        placeholder="Canceled Amount"
                        sizing="md"
                      />
                      <DateField name="canceledDate" placeholder="Canceled Date" sizing="md" />
                      <CurrencyField
                        name="disbursedAmount"
                        placeholder="Original Gross Disbursed Amount"
                        sizing="md"
                      />
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel key={2}>
                  <Accordion.Title>
                    <PanelHeader>Dates</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div className="grid grid-cols-2 gap-y-2 gap-x-9 mt-4">
                      <DateField
                        name="periodBeginDate"
                        placeholder="Period Begin Date"
                        sizing="md"
                      />
                      <DateField name="periodEndDate" placeholder="Period End Date" sizing="md" />
                      <DateField
                        name="repaymentBeginDate"
                        placeholder="Repayment Begin Date"
                        sizing="md"
                      />
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel key={3}>
                  <Accordion.Title>
                    <PanelHeader>Interest rate</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div className="grid grid-cols-2 gap-y-2 gap-x-9 mt-4">
                      <SelectField
                        isSearchable={false}
                        name="interestRateType"
                        label="Interest Rate Type"
                      >
                        <option value={'FIXED'}>Fixed </option>
                        <option value={'VARIABLE'}>Variable </option>
                      </SelectField>
                      <PercentageField
                        name="interestRate"
                        placeholder="Interest Rate"
                        sizing="md"
                      />
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    <PanelHeader>Payment</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div className="grid grid-cols-2 gap-y-2 gap-x-9 mt-4">
                      <CurrencyField
                        name="cumulativePaymentAmount"
                        placeholder="Cumulative Paid Amount"
                        sizing="md"
                      />
                      <DateField
                        name="mostRecentPaymentEffectiveDate"
                        placeholder="Most Recent Payment Effective Date"
                        sizing="md"
                      />
                      <DateField
                        name="nextPaymentDueDate"
                        placeholder="Next Payment Due Date"
                        sizing="md"
                      />
                      <div className="flex gap-4">
                        <TextInputField
                          className=" w-full"
                          name="pslfCumulativeMatchedMonths"
                          placeholder="PSLF Cumulative Matched Months"
                          sizing="md"
                        />
                        <CheckboxField
                          className="mt-8 w-full"
                          name="applyPslfCumulativeMatchedMonthsToAllLoans"
                          label="Apply this to all Loans"
                        />
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel key={5}>
                  <Accordion.Title>
                    <PanelHeader>Reaffirmation</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div className="grid grid-cols-2 gap-y-2 gap-x-9 mt-4">
                      <DateField
                        name="reaffirmationDate"
                        placeholder="Reaffirmation Date"
                        sizing="md"
                      />
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel key={6}>
                  <Accordion.Title>
                    <PanelHeader>Repayment</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div className="grid grid-cols-2 gap-y-2 gap-x-9 mt-4">
                      <SelectField
                        name="repaymentPlanType"
                        tooltip={
                          <div className="flex flex-col gap-4">
                            <span>For Consolidated Loans, Debt Repayment Timeline would be:</span>
                            <span>$0-$7,500: 10 years</span>
                            <span>$7,500-$10,000: 12 years</span>
                            <span>$10,000-$20,000: 15 years</span>
                            <span>$20,000-$40,000: 20 years</span>
                            <span>$40,000-$60,000: 25 years</span>
                            <span>$60,000 or more: 30 years</span>
                          </div>
                        }
                        label="Repayment Plan Type"
                      >
                        {KnownRepaymentPlanTypes.map((t) => (
                          <option key={t} value={t}>
                            {t == 'CONSOLIDATED'
                              ? 'CONSOLIDATED (STANDARD/EXTENDED)'
                              : t == 'EXTENDED'
                              ? 'EXTENDED (25 YEARS)'
                              : t == 'STANDARD'
                              ? 'STANDARD (10 YEARS)'
                              : t}{' '}
                          </option>
                        ))}
                        {!values.repaymentPlanType ||
                        KnownRepaymentPlanTypes.includes(values.repaymentPlanType) ? null : (
                          <option key={values.repaymentPlanType} value={values.repaymentPlanType}>
                            {values.repaymentPlanType}
                            {' * '}
                          </option>
                        )}
                      </SelectField>
                      <CheckboxField
                        className="mt-8"
                        name="applyRepaymentPlanTypeToAllLoans"
                        label="Apply Repayment Plan Type to all Loans"
                      />

                      <DateField
                        name="repaymentPlanBeginDate"
                        placeholder="Repayment Plan Begin Date"
                        sizing="md"
                      />
                      <CurrencyField
                        name="repaymentPlanScheduledAmount"
                        placeholder="Repayment Plan Scheduled Amount"
                        sizing="md"
                      />

                      <DateField
                        name="repaymentPlanIdrPlanAnniversaryDate"
                        placeholder="Repayment Plan IDR Plan Anniversary Date"
                        sizing="md"
                      />
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel key={7}>
                  <Accordion.Title>
                    <PanelHeader>School</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div className="grid grid-cols-3 gap-y-2 gap-x-9 mt-4 max-md:grid-cols-2">
                      <TextInputField
                        name="attendingSchoolName"
                        placeholder="Attending School Name"
                        sizing="md"
                      />
                      <TextInputField
                        name="attendingSchoolOpeid"
                        placeholder="Attending School OPEID"
                        sizing="md"
                      />{' '}
                      <TextInputField name="awardId" placeholder="Award ID" sizing="md" />
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel key={8}>
                  <Accordion.Title>
                    <PanelHeader>Servicer</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div className="grid grid-cols-3 gap-y-2 gap-x-9 mt-4">
                      <TextInputField name="contactCity" placeholder="City" sizing="md" />
                      <TextInputField name="contactEmail" placeholder="Email" sizing="md" />
                      <TextInputField name="contactName" placeholder="Name" sizing="md" />
                      <TextInputField
                        name="contactPhoneExtension"
                        placeholder="Phone Extension"
                        sizing="md"
                      />
                      <TextInputField
                        name="contactPhoneNumber"
                        placeholder="Phone Number"
                        sizing="md"
                      />
                      <TextInputField
                        name="contactStateCode"
                        placeholder="State Code"
                        sizing="md"
                      />
                      <TextInputField
                        name="contactStreetAddress"
                        placeholder="Street Address"
                        sizing="md"
                      />

                      <TextInputField
                        name="contactStreetAddress2"
                        placeholder="Street Address 2"
                        sizing="md"
                      />

                      <TextInputField name="contactType" placeholder="Type" sizing="md" />
                      <TextInputField name="contactZipCode" placeholder="Zip Code" sizing="md" />
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel key={9}>
                  <Accordion.Title>
                    <PanelHeader>Subsidy</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div className="grid grid-cols-2 gap-y-2 gap-x-9 mt-4">
                      <TextInputField
                        name="confirmedSubsidyStatus"
                        placeholder="Confirmed Subsidy Status"
                        sizing="md"
                      />
                      <TextInputField
                        name="subsidizedUsageInYears"
                        placeholder="Subsidized Usage in Years"
                        sizing="md"
                      />
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel key={10}>
                  <Accordion.Title>
                    <PanelHeader>Statuses</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div>
                      <FieldArray
                        name="statuses"
                        render={(arrayHelpers) => (
                          <div>
                            {values?.statuses?.map((friend: any, index: number) => (
                              <>
                                <div
                                  className=" grid grid-cols-4 gap-y-2 gap-x-9 mt-4 items-center max-md:grid-cols-2"
                                  key={index}
                                >
                                  <TextInputField
                                    name={`statuses[${index}].status`}
                                    placeholder="Status"
                                    sizing="md"
                                  />
                                  <TextInputField
                                    name={`statuses[${index}].description`}
                                    placeholder="Description"
                                    sizing="md"
                                  />
                                  <div className="relative w-min">
                                    <DateField
                                      name={`statuses[${index}].effectiveDate`}
                                      placeholder="Effective Date"
                                      className="pr-8"
                                      sizing="md"
                                    />
                                    <HiOutlineTrash
                                      className="text-red-500 hover:text-red-600 hover:cursor-pointer absolute right-0 top-[28px]"
                                      size="24"
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </div>
                                </div>
                              </>
                            ))}
                            <Button className="mt-8" onClick={() => arrayHelpers.push({})}>
                              Add Status
                            </Button>
                          </div>
                        )}
                      />
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export const UpsertFederalLoanFlyout = () => {
  return (
    <Flyout
      id={`upsert-federal-loan`}
      requiredParams={['clientId', 'loanId', 'createNewVersion']}
      content={<UpsertFederalLoanForm />}
    ></Flyout>
  );
};
